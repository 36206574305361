import Home from "./home/Home";
import ListTenantAccounts from "./accounts/ListTenantAccounts"
import ListTenantCompanies from "./companies/ListTenantCompanies";
import AccountParent from "./accounts/AccountParent";
import CompanyMailParent from "./mail/CompanyMailParent";
import CreateAccount from "./accounts/CreateAccount";
import AddCompanyParent from "./addCompany/AddCompanyParent";
import AddCompanySearch from "./addCompany/AddCompanySearch";
import CompanyParent from "./companies/CompanyParent";
import UpdatePerson from "./companies/primaryContact/UpdatePerson";
import CreatePerson from "./companies/primaryContact/CreatePerson";
import FinishCardSetup from "./billing/FinishCardSetup";
import CheckInMail from "./scannedMail/checkInMail";
import Login from "./users/Login";
import ListClients from "./clients/ListClients";
import CreateClient from "./clients/CreateClient";
import ClientParent from "./clients/ClientParent";
import ProcessScannedMail from "./scannedMail/processScannedMail";
import ListTenantMail from "./mail/ListTenantMail";
import ListMyMail from "./mail/ListMyMail";
import UpdateMail from "./mail/UpdateMail";
import ListCompaniesHouse from "./companiesHouse/ListCompaniesHouse";
import ViewCompaniesHouse from "./companiesHouse/ViewCompaniesHouse";
import ProcessUnknownScannedMail from "./scannedMail/processUnknownScannedMail";
import ListVirtualSquatters from "./reports/ListVirtualSquatters";
import AcceptInvitation from "./users/AcceptInvitation";
import RequestPasswordReset from "./users/RequestPasswordReset";
import ResetPassword from "./users/ResetPassword";
import MyProfile from "./users/MyProfile";
import ListTenantUsers from "./tenants/ListTenantUsers";
import CreateTenantUser from "./tenants/CreateTenantUser";
import CreateClientUser from "./clients/CreateClientUser";
import UpdateTenantUser from "./tenants/UpdateTenantUser";
import UpdateClientUser from "./clients/UpdateClientUser";
import UpdateAccountUser from "./accounts/UpdateAccountUser";
import CreateAccountUser from "./accounts/CreateAccountUser";
import AddAccountBillingParent from "./accounts/AddAccountBillingParent";
import BillingParent from "./billing/BillingParent";
import AddCompanyBillingParent from "./companies/AddCompanyBillingParent";
import AddNewProduct from "./products/AddNewProduct";
import SubscriptionDetail from "./billing/SubscriptionDetail";
import CheckoutMail from "./mail/CheckoutMail";
import MyCompanies from "./companies/MyCompanies";
import MyTeam from "./users/MyTeam";
import ListClientAccounts from "./accounts/ListClientAccounts";
import ListClientCompanies from "./companies/ListClientCompanies";
import ListClientMail from "./mail/ListClientMail";
import ClientUsers from "./users/ClientUsers";
import ListDissolvedCompanies from "./reports/ListDissolvedCompanies";
import ListPaymentDefaults from "./reports/ListPaymentDefaults";
import Support from "./home/Support";
import MyDocuments from "./home/MyDocuments";
import Resources from "./home/Resources";
import ListMailCheckouts from "./mail/ListMailCheckouts";
import ListMailReport from "./reports/ListMailReport";
import CsvImport from "./import/CsvImport";
import ListPaymentDisputes from "./reports/ListPaymentDisputes";
import AccountDetailsConfirmationParent from "./onboarding/AccountDetailsConfirmationParent";
import RefeshCompanies from "./util/RefeshCompanies";
import NonLoggedInTermsAndConditions from "./termsandconditions/GuestTermsAndConditions";
import TermsAndConditionsTabs from "./termsandconditions/TermsAndConditionsTabs";
import AllCompanyDisplay from "./companies/AllCompanyDisplay";
import ListCsvImportHistory from "./import/ListCsvImportHistory";
import ListCsvImportJobRows from "./import/ListCsvImportJobRows";
import PreSortBatchesParent from "./scannedMail/PreSortBatchesParent";
import ScannedMailBatchesParent from "./scannedMail/ScannedMailBatchesParent";
import UnknownMailBatchesParent from "./scannedMail/UnknownMailBatchesParent";
import ProcessPreSortMail from "./scannedMail/processPreSortMail";
import CompanyReport from "./reports/CompanyReport";
import AddPostBoxParent from "./addCompany/AddPostBoxParent";
import ListTenantPostBoxes from "./companies/ListTenantPostBoxes";


const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/Account/ListTenantAccounts',
    element: <ListTenantAccounts />
  },
  {
    path: '/Account/ListClientAccounts',
    element: <ListClientAccounts />
  },
  {
    path: '/Company/ListClientCompanies',
    element: <ListClientCompanies />
  },
  {
    path: '/Company/ListTenantCompanies',
    element: <ListTenantCompanies />
  },
  {
    path: '/Company/ListTenantPostBoxes',
    element: <ListTenantPostBoxes />
  },
  {
    path: '/Company/MyCompanies',
    element: <MyCompanies />
  },
  {
    path: '/Account/Update/:accountId/:selectedTab',
    element: <AccountParent />
  },
  {
    path: '/Account/AddCompany/:accountId',
    element: <AddCompanySearch />
  },
  {
    path: '/Account/AddCompany/:accountId/:companyNumber',
    element: <AddCompanyParent />
  },
  {
    path: '/Account/AddPostBox/:accountId',
    element: <AddPostBoxParent />
  },
  {
    path: '/Account/Create',
    element: <CreateAccount />
  },
  {
    path: '/Company/Update/:companyId/:selectedTab',
    element: <CompanyParent />
  },
  {
    path: '/Person/Update/:personId',
    element: <UpdatePerson />
  },
  {
    path: '/Person/Create/:companyId',
    element: <CreatePerson />
  },
  {
    path: '/Users/ListClientUsers',
    element: <ClientUsers />
  },
  {
    path: '/Tenant/ListTenantUsers',
    element: <ListTenantUsers />
  },
  {
    path: '/Tenant/CreateTenantUser',
    element: <CreateTenantUser />
  },
  {
    path: '/Tenant/UpdateTenantUser/:userId',
    element: <UpdateTenantUser />
  },
  {
    path: '/Client/UpdateUser/:userId',
    element: <UpdateClientUser />
  },
  {
    path: '/Account/UpdateUser/:userId',
    element: <UpdateAccountUser />
  },
  {
    path: '/Account/AddBilling/:accountId',
    element: <AddAccountBillingParent />
  },
  {
    path: '/Company/AddBilling/:companyId',
    element: <AddCompanyBillingParent />
  },
  {
    path: '/Product/AddNew/:resourceType/:resourceId',
    element: <AddNewProduct />
  },
  {
    path: 'Billing/Update/:resourceType/:resourceId/:selectedTab',
    element: <BillingParent/>
  },
  {
    path: '/Billing/FinishCardSetup/:resourceType/:resourceId',
    element: <FinishCardSetup />
  },
  {
    path: '/Mail/CheckIn',
    element: <CheckInMail/>
  },
  {
    path: '/Mail/ProcessScannedMail/:scanBatchId',
    element: <ProcessScannedMail/>
  },
  {
    path: '/Mail/ProcessUnknownScannedMail/:scanBatchId',
    element: <ProcessUnknownScannedMail/>
  },
  {
    path: '/Client/List',
    element: <ListClients />
  },
  {
    path: '/Client/Create',
    element: <CreateClient />
   },
  {
    path: '/Client/CreateUser/:clientId',
    element: <CreateClientUser />
  },
   {
     path: '/Client/Update/:clientId/:selectedTab',
     element: <ClientParent />
   },
  {
    path: '/Account/CreateUser/:accountId',
    element: <CreateAccountUser />
  },
  {
    path: '/Mail/ListTenantMail',
    element: <ListTenantMail />
  },
  {
    path: '/Mail/ListClientMail',
    element: <ListClientMail />
  },
  {
    path: '/Mail/ListMailCheckouts',
    element: <ListMailCheckouts />
  },
  {
    path: '/Mail/CompanyMail/:companyId',
    element: <CompanyMailParent />
  },
  {
    path: '/Mail/ListMyMail',
    element: <ListMyMail />
  },
  {
    path: '/Mail/Update/:mailId',
    element: <UpdateMail />
  },
  {
    path: '/CompaniesHouse/List',
    element: <ListCompaniesHouse />
  },
  {
    path: '/CompaniesHouse/ViewCompany/:companyNumber',
    element: <ViewCompaniesHouse />
  },
  {
    path: '/Reports/VirtualSquatters/List',
    element: <ListVirtualSquatters />
  },
  {
    path: '/Reports/MailReport/List',
    element: <ListMailReport />
  },
  {
    path: '/Reports/CompanyReport',
    element: <CompanyReport />
  },
  {
    path: '/Reports/DissolvedCompanies/List',
    element: <ListDissolvedCompanies />
  },
  {
    path: '/Reports/PaymentDefaults/List',
    element: <ListPaymentDefaults />
  },
  {
    path: '/MyProfile',
    element: <MyProfile />
  },
  {
    path: 'Users/:userId/AcceptInvitation/:token',
    element: <AcceptInvitation />
  },
  {
    path: '/Login',
    element: <Login/>
  },
  {
    path: '/Users/RequestPasswordReset',
    element: <RequestPasswordReset/>
  },
  {
    path: '/TermsAndConditions/NonLoggedIn',
    element: <NonLoggedInTermsAndConditions/>
  },
  {
    path: '/TermsAndConditions',
    element: <TermsAndConditionsTabs/>
  },
  {
    path: '/GuestTermsAndConditions',
    element: <NonLoggedInTermsAndConditions/>
  },
  {
    path: 'Billing/Subscription/:resourceType/:resourceId/:subscriptionId',
    element: <SubscriptionDetail/>
  },
  {
    path: 'Mail/Checkout',
    element: <CheckoutMail/>
  },
  {
    path: 'Users/MyTeam',
    element: <MyTeam/>
  },
  {
    path: 'Import/CsvImport',
    element: <CsvImport/>
  },
  {
    path: 'Import/CsvImportHistory',
    element: <ListCsvImportHistory/>
  },
  {
    path: 'Import/ListCsvImportJobRows/:csvImportJobId',
    element: <ListCsvImportJobRows/>
  },
  {
    path: 'RefreshCompanies',
    element: <RefeshCompanies/>
  },
  {
    path: 'Support',
    element: <Support/>
  },
  {
    path: 'Resources',
    element: <Resources/>
  },
  {
    path: 'MyDocuments',
    element: <MyDocuments/>
  },
  {
    path: 'Users/:userId/ResetPassword/:token',
    element: <ResetPassword/>
  },
  {
    path: 'Reports/PaymentDisputes/List',
    element: <ListPaymentDisputes/>
  },
  {
    path: 'Onboarding/ConfirmAccountDetails',
    element: <AccountDetailsConfirmationParent/>
  },
  {
      path: 'Companies/AllCompanyDisplay',
      element: <AllCompanyDisplay/>
  },
  {
      path: 'Mail/PreSortMailBatches',
      element: <PreSortBatchesParent />
  },
  {
    path: 'Mail/ScannedMailBatches',
    element: <ScannedMailBatchesParent/>
  },
  {
    path: 'Mail/UnknownMailBatches',
    element: <UnknownMailBatchesParent/>
  },
  {
    path: 'Mail/PreSortMail/:scanBatchId',
    element: <ProcessPreSortMail />
  },
];

export default AppRoutes;