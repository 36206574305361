import React, {useContext, useEffect, useState} from 'react';
import Button from "@mui/material/Button";
import {
    CardContent, Card, Typography, CardMedia, Grid, CircularProgress, Stack
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import AuthContext from "../api-authorization/AuthContext";
import {useForm} from "react-hook-form";
import {enqueueSnackbar} from "notistack";
import productService from "./ProductService";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Checkout from "./Checkout";
import {RESOURCE_TYPE} from "../enums";
import accountService from "../accounts/AccountService";
import companyService from "../companies/CompanyService";
import NavigationContext from "../layout/NavigationContext";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function AddNewProduct() {
    const { histNavigate, back } = useContext(NavigationContext);
    const { resourceType, resourceId } = useParams();
    const { antiForgeryToken, refreshUser,user} = useContext(AuthContext);
    const {  handleSubmit, control, formState, setValue } = useForm();
    const navigate = useNavigate();
    const [isBusy, setIsBusy] = React.useState(true);
    const [showCheckout, setShowCheckout] = React.useState(false);
    const [mailForwardingOrMailScanningAdded, setMailForwardingOrMailScanningAdded] = React.useState(null);
    
    const [tabValue, setTabValue] = React.useState(0);

    const [resourceLoading, setResourceLoading] = useState(true);
    const [resourceData, setResourceData] = useState();
    
    const [products, setProducts] = React.useState([]);
    const [cart, setCart] = useState([]);
    const [productTabHeaders, setProductTabHeaders] = useState([]);
    
    const handleChange = (event, newTabValue) => {
        setTabValue(newTabValue);
    };

    async function fetchResourceData() {
        let result;
        if(resourceType == RESOURCE_TYPE.ACCOUNT){
            result = await accountService.getAccount(antiForgeryToken, resourceId);
        }
        else{
            result = await companyService.getCompany(antiForgeryToken, resourceId);
        }

        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                setResourceData(data);
                setResourceLoading(false);
            } else {
                enqueueSnackbar(data.description);
            }
        }
    }

    async function fetchProducts() {
        const result = await productService.listProducts(antiForgeryToken, resourceType, resourceId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                setProducts(data.products);
                setProductTabHeaders(data.products.map(product => product.tab)
                    .filter((value, index, self) => self.indexOf(value) === index));
                setIsBusy(false);
            } else {
                enqueueSnackbar(data.description);
            }
        }
    }

    useEffect( () => {
        fetchResourceData();
        fetchProducts();
    }, [resourceType, resourceId]);

    function addToCart(value, mailForwardingType, mailScanning){
        if(mailForwardingType > 0 || mailScanning === true){
            setMailForwardingOrMailScanningAdded(value);
        }
        setCart(cart => [...cart, value]);
    }

    function cartContains(value){
        return cart.includes(value);
    }
    
    function disableMailForwardingOrMailScanning(filteredProduct) {
        let disableMailForwardingOrMailScanning = false;
        if((filteredProduct.mailForwardingType != null && filteredProduct.mailForwardingType > 0) || (filteredProduct.mailScanning === true)){
            if(mailForwardingOrMailScanningAdded != null && mailForwardingOrMailScanningAdded != filteredProduct.id){
                disableMailForwardingOrMailScanning =  true;
            }
            else if((resourceType == RESOURCE_TYPE.COMPANY || resourceType == RESOURCE_TYPE.POST_BOX) && resourceData.hasActiveMailForwardingOrMailScanningProduct) {
                disableMailForwardingOrMailScanning = true;
            }
        }
        return disableMailForwardingOrMailScanning;
    }

    function removeFromCart(value, mailForwardingType, mailScanning){
        if(mailForwardingType > 0 || mailScanning === true){
            setMailForwardingOrMailScanningAdded(null);
        }
        setCart(cart.filter(item => item !== value));
    }
    
    let content;
    if (isBusy || resourceLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress/>
            </Box>
        );
    } 
    else if(showCheckout){
        content = (
            <>
                <Checkout antiForgeryToken={antiForgeryToken} resourceType={resourceType} resourceId={resourceId} cart={cart} products={products} refreshUser={refreshUser} user={user}/>
                <Stack direction="row" spacing={2} marginTop={2}>
                    <Button variant="contained" color="secondary" onClick={(e) => {
                        setShowCheckout(false);
                    }}
                    >
                        Back
                    </Button>
                </Stack>
            </>
        );
    }
    else {
        let tabsHeaderContent = productTabHeaders.map((tab,index) => (
            <Tab key={`${tab}_tab`} label={tab} id={`simple-tab-${index}`} aria-controls={`simple-tabpanel-${index}`}/>
        ));

        let tabsContent = productTabHeaders.map((tab,index) => (
            <TabPanel key={`${tab}_panel`} value={tabValue} index={index}>
                {
                    products.filter(product => product.tab === tab)
                        .map(filteredProduct => (
                            <Grid container spacing={2} key={`${filteredProduct.id}_grid`} sx={{mt: 4}}>
                                <Grid item xs={16} sm={12} md={10} key={`${filteredProduct.id}_card`}>
                                    <Card sx={{ display: 'flex', backgroundColor: "#FBFBFB", boxShadow: 0, borderColor: "#EBEBEB", borderWidth: 1, borderStyle:"solid" }}>
                                        <CardMedia
                                            component="img"
                                            sx={{ width: 200, height:200 }}
                                            image={ filteredProduct.images.toString() }
                                            alt=""
                                        />
                                        <Box sx={{ display: 'flex', flexDirection: 'column', width:"100%" }}>
                                            <CardContent sx={{ flex: '1 0 auto' }}>
                                                <Typography component="div" variant="h5">
                                                    {filteredProduct.name}
                                                </Typography>
                                                <Typography variant="subtitle1" color="text.secondary" component="div">
                                                    {filteredProduct.description}
                                                </Typography>
                                                <Typography variant="productPriceDisplay" color="text.secondary" component="div" sx={{ textAlign: 'right' }}>
                                                    {filteredProduct.priceDisplay}
                                                </Typography>
                                                <Typography variant="productVatDisplay" color="text.secondary" component="div" sx={{ textAlign: 'right' }}>
                                                    + VAT {filteredProduct.interval ? `| PER ${filteredProduct.interval}` : ""}
                                                </Typography>
                                                {filteredProduct.freeTrialDays != null && filteredProduct.freeTrialDays > 0 && (
                                                    <Typography variant="productVatDisplay" color="text.secondary" component="div" sx={{ textAlign: 'right' }}>
                                                        {filteredProduct.freeTrialDays} days free trial
                                                    </Typography>
                                                )}
                                            </CardContent>
    
                                        </Box>
                                    </Card>
                                </Grid>
                                <Grid item xs={8} sm={4} md={2} key={`${filteredProduct.id}_button`}
                                      container
                                      direction="row"
                                      justifyContent="center"
                                      alignItems="center"
                                >
                                    <Button variant="contained" color="secondary" onClick={() =>
                                        cartContains(filteredProduct.id) ? removeFromCart(filteredProduct.id, filteredProduct.mailForwardingType, filteredProduct.mailScanning) :addToCart(filteredProduct.id, filteredProduct.mailForwardingType, filteredProduct.mailScanning)
                                    }
                                            disabled={disableMailForwardingOrMailScanning(filteredProduct)}
                                    >
                                        {cartContains(filteredProduct.id) ? "Remove" : "Add"}
                                    </Button>
                                </Grid>
                            </Grid>
                            )
                        )
                }
            </TabPanel>
        ));
        
        let headerDetails = ``;
        if(resourceType == RESOURCE_TYPE.ACCOUNT){
            headerDetails = `account - ${resourceData.email}`;
        }
        else if(resourceType == RESOURCE_TYPE.COMPANY){
            headerDetails = `company - ${resourceData.name}`;
        }
        else if(resourceType == RESOURCE_TYPE.POST_BOX){
            headerDetails = `post box - ${resourceData.postBoxNumber}`;
        }
        
        content = (
            <>
                <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb: 2}}>
                    Add products & services to {headerDetails}
                </Typography>
                <Box>
                    <Box>
                        <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                            {tabsHeaderContent}
                        </Tabs>
                    </Box>
                    <Box  sx={{minHeight:400, maxWidth: 1200}}>
                        {tabsContent}
                    </Box>
                    <Stack direction="row" spacing={2} marginTop={2}>
                        <Button variant="contained" color="secondary" onClick={(e) => {
                            if(tabValue <= 0) {
                                back();
                            }
                            else {
                                setTabValue(tabValue-1);
                            }
                        }}
                        >
                            Back
                        </Button>
                        <Button variant="contained" color="secondary" type="submit" onClick={(e) => {
                            setTabValue(tabValue+1);
                        }}
                        disabled={tabValue >= productTabHeaders.length - 1}
                        >
                            Next
                        </Button>
                        <Button variant="contained" color="secondary" type="submit"
                                onClick={(e) => {
                                    setShowCheckout(true);
                                }}
                                disabled={cart.length==0}>
                            Checkout
                        </Button>
                    </Stack>
                </Box>
            </>
        );
    }
    
    return (
        content
    );

}