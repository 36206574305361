import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useContext, useState} from "react";
import {enqueueSnackbar} from "notistack";
import {Controller, useForm} from "react-hook-form";
import TextField from "@mui/material/TextField";
import {Container, Grid, Stack} from "@mui/material";
import Button from "@mui/material/Button";
import {useNavigate, useParams} from "react-router-dom";
import inputService from "../util/InputService";
import userService from "./UserService";
import AuthContext from "../api-authorization/AuthContext";
import CssBaseline from "@mui/material/CssBaseline";
import {useTheme} from "@mui/material/styles";
import {ArrowRight} from "@mui/icons-material";

export default function AcceptInvitation() {
    const theme = useTheme();
    const { userId } = useParams();
    const { token} = useParams();
    const {  handleSubmit, control, formState, watch } = useForm();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const watchPassword = watch("password", "");
    const navigate = useNavigate();
    const { antiForgeryToken} = useContext(AuthContext);

    const handleFormSubmit  = async (formData) => {
        setDisableSubmit(true);
        formData.userId = userId;
        formData.token = token;
        formData = inputService.trimFields(formData);
        const result = await userService.acceptInvitation(antiForgeryToken, formData);

        let data = await result.json();
        if (result.ok) {
            navigate('/login');
            enqueueSnackbar("Successfully accepted invitation");
        } else {
            enqueueSnackbar(data.description);
            if(data.code==="AcceptInvitationUsed"){
                navigate('/login');
            }
        }

        setDisableSubmit(false);
    };

    
    return (
        <>
            <CssBaseline />
            <Box
                sx={{
                    backgroundImage: `url(${theme.loginBackgroundImage})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    width: '100%',
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Container component="main" sx={{backgroundColor:"white",border:2,borderColor:'grey.200',pt:6,pb:10, width:"500px"}}>
                    <Box
                        component="form" noValidate onSubmit={handleSubmit(handleFormSubmit)}
                        sx={{
                            marginTop: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <img src={theme.loginLogo} alt="logo" style={{height: "80px"}}/>
                        <Typography variant="h5" sx={{mt:4, mb:3}}>
                            Create a password
                        </Typography>
                        <Typography variant="h6" sx={{textAlign:"center"}}>
                            For your password to be up to the latest standards,
                            please consider the following when creating your password:
                        </Typography>
                        <Grid container alignItems="center" sx={{ flexWrap: 'nowrap',mt:4 }}>
                            <Grid item>
                                <ArrowRight sx={{ fontSize: "22px", color: 'yourColor', mt: 'auto', mb: 'auto' }} />
                            </Grid>
                            <Grid item xs zeroMinWidth>
                                <Typography sx={{ textAlign: "left", fontSize: "15px", overflowWrap: 'anywhere' }}>
                                    The password should consist of a minimum of 12 characters
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" sx={{ flexWrap: 'nowrap' }}>
                            <Grid item>
                                <ArrowRight sx={{ fontSize: "22px", color: 'yourColor', mt: 'auto', mb: 'auto' }} />
                            </Grid>
                            <Grid item xs zeroMinWidth>
                                <Typography sx={{ textAlign: "left", mt: 1, fontSize: "15px", overflowWrap: 'anywhere' }}>
                                    A combination of uppercase letters, lowercase letters, numbers, and symbols
                                </Typography>
                            </Grid>
                        </Grid>
                        <Box sx={{width:"80%",mt:4}}>
                            <Controller
                                name="password"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "Password is required", minLength: { value: 12, message: 'Must be 12 characters long'}
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Password"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        type={"password"}
                                        error={!!formState.errors.password}
                                        helperText={formState.errors.password ? formState.errors.password.message : ''}
                                    />
                                )}
                            />
    
    
                            <Controller
                                name="repeatPassword"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "Repeat Password is required",
                                    validate: {
                                        passwordsEqual: value => (value === watchPassword) || "Password's are not identical",
                                    },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Repeat Password"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        type={"password"}
                                        size="small"
                                        error={!!formState.errors.repeatPassword}
                                        helperText={formState.errors.repeatPassword ? formState.errors.repeatPassword.message : ''}
                                    />
                                )}
                            />
                        </Box>
                        <Stack direction="row" spacing={2} marginTop={2}>
                            <Button variant="contained" color="secondary" type="submit" disabled={disableSubmit}>
                                Activate Account
                            </Button>
                        </Stack>
                    </Box>
                </Container>
            </Box>
        </>
    );
}