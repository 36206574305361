import * as React from 'react';
import { useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {COMPANY_TABS, COMPANY_TYPE, MAIL_TABS, ROLES} from "../enums";
import {enqueueSnackbar} from "notistack";
import AuthContext from "../api-authorization/AuthContext";
import {CircularProgress} from "@mui/material";
import companyService from "../companies/CompanyService";
import UpdateMailSettings from "../companies/UpdateMailSettings";
import ListCompanyMail from "./ListCompanyMail";
import Breadcrumb from "../layout/Breadcrumb";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function CompanyMailParent() {
    const { antiForgeryToken, refreshUser,user } = useContext(AuthContext);
    const { selectedTab, companyId} = useParams();
    const [ companyLoading, setCompanyLoading] = useState(true);
    const [ companyData, setCompanyData] = useState();
    
    const [tabValue, setTabValue] = React.useState(selectedTab ? parseInt(selectedTab) : 0);
    const navigate = useNavigate();
    
    let content;
    const handleChange = (event, newTabValue) => {
        setTabValue(newTabValue);
    };

    useEffect( () => {
        async function fetchData() {
            const result = await companyService.getCompany(antiForgeryToken, companyId);
            
            if (result.status === 401) {
                refreshUser();
                navigate('/login');
            }
            else {
                let data = await result.json();
                if (result.ok) {
                    if(data.companyType === COMPANY_TYPE.POST_BOX) {
                        data.breadcrumbItems.push({description: `Post Box - ${data.postBoxNumber}`, url: `/Company/Update/${data.id}/${COMPANY_TABS.DETAILS}`});
                    }
                    else{
                        data.breadcrumbItems.push({description: `Company - ${data.name}`, url: `/Company/Update/${data.id}/${COMPANY_TABS.DETAILS}`});   
                    }
                    setCompanyData(data);
                    setCompanyLoading(false);

                } else {
                    enqueueSnackbar(data.description); 
                }
            }
        }
        fetchData();
    }, [companyId])

    
    
    if (companyLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        content = (
            <>
                {(user.role === ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD || user.role === ROLES.CLIENT_ADMIN || user.role === ROLES.CLIENT_STANDARD) &&(
                    <Breadcrumb breadcrumbItems={companyData.breadcrumbItems}/>
                )}
                
                <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb: 2}}>
                    Mail - {companyData.companyType === COMPANY_TYPE.POST_BOX ? companyData.postBoxNumber : companyData.name}
                </Typography>
                <Box>
                    <Box>
                        <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Mail History" id={`simple-tab-${MAIL_TABS.MAIL_HISTORY}`} aria-controls={`simple-tabpanel-${MAIL_TABS.MAIL_HISTORY}`}/>
                            {(user.role === ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD) &&(
                                <Tab label="Mail Settings" id={`simple-tab-${MAIL_TABS.MAIL_SETTINGS}`} aria-controls={`simple-tabpanel-${MAIL_TABS.MAIL_SETTINGS}`}/>
                            )}
                        </Tabs>
                    </Box>
                    <TabPanel value={tabValue} index={0}>
                        <ListCompanyMail companyId={companyId} antiForgeryToken={antiForgeryToken} refreshUser={refreshUser}/>
                    </TabPanel>
                    {(user.role === ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD) &&(
                        <TabPanel value={tabValue} index={1}>
                            <UpdateMailSettings companyId={companyId} antiForgeryToken={antiForgeryToken} refreshUser={refreshUser}/>
                        </TabPanel>
                    )}
                </Box>
            </>
        );
    }

    return (
        <>
            {content}
        </>
    );
}