//TODO refactor this out so it's in seperate files

export const ROLES = {
    TENANT_ADMIN: "TenantAdmin",
    TENANT_STANDARD: "TenantStandard",
    CLIENT_ADMIN: "ClientAdmin", 
    CLIENT_STANDARD: "ClientStandard",
    ACCOUNT_ADMIN : "AccountAdmin",
    ACCOUNT_STANDARD: "AccountStandard",
}

export const TAX_RATE ={
    UK: 0.2
}

export const ACCOUNT_TABS = {
    DETAILS: 0,
    USERS: 1,
    BILLING: 2,
    COMPANIES: 3,
    PO_BOXES: 4,
    PRODUCTS: 5
}

export const COMPANY_TABS = {
    DETAILS: 0,
    CONTACTS: 1,
    BILLING: 2,
    PRODUCTS: 3,
    MAIL: 4
}

export const CLIENT_TABS = {
    DETAILS: 0,
    USERS: 1
}

export const BILLING_TABS = {
    PAYMENT_METHOD: 0,
    PAYMENT_HISTORY: 1
}

export const MAIL_TABS = {
    MAIL_HISTORY: 0,
    MAIL_SETTINGS: 1
}

export const RESOURCE_TYPE = {
    ACCOUNT: 1,
    COMPANY: 2,
    POST_BOX: 3
}

export const COMPANY_TYPE = {
    COMPANY: 1,
    POST_BOX: 2
}

export const SCANNED_MAIL_TYPE = {
    SCANNED: 1,
    UNKNOWN: 2,
    PRE_SORT: 3
}

export const MAIL_PROCESS_STATUS = {
    RECEIVED: 1,
    SCANNED : 2,
    SENT : 3
}

export const USER_ROLES = {
    TENANT_ADMIN : 1,
    TENANT_STANDARD : 2,
    CLIENT_ADMIN : 3,
    CLIENT_STANDARD : 4,
    ACCOUNT_ADMIN : 5,
    ACCOUNT_STANDARD : 6,
}

export const MAIL_TYPE = {
    NONE: 0,
    ROYAL_MAIL_LETTER: 1,
    ROYAL_MAIL_LARGE_LETTER: 2,
    ROYAL_MAIL_SMALL_PARCEL: 3,
    ROYAL_MAIL_MEDIUM_PARCEL: 4,
    ROYAL_MAIL_LARGE_PARCEL: 5,
    COMPANIES_HOUSE: 6,
    THE_PENSION_REGULATOR: 7,
    HMRC: 8,
    OTHER_SPECIAL_DELIVERY: 9,
    OTHER_STATUTORY_MAIL: 10,
    PRINTED_MATTER: 11
}

export const FIND_COMPANIES_MAIL_SETTINGS_RESULT_TYPE = {
    COMPANY: 1,
    PERSON: 2,
    POST_BOX: 3
}

