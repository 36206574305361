import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from "@mui/material/TextField";
import {Box, Checkbox, FormControlLabel, Grid, MenuItem} from "@mui/material";
import Typography from "@mui/material/Typography";
import {FormGroup} from "reactstrap";
import {useTheme} from "@mui/material/styles";
import {COMPANY_TYPE} from "../../enums";

export default function PrimaryContactForm({formState, control, defaultValues,setIsForwardingAddressAmlApproved,checkIfPersonRequiresAmlApproval,forwardingAddressRequiresAmlApproval, companyType}) {
    const theme = useTheme();
    
    let primaryContactLabel = "Tick here to make this contact the Primary Contact for this business.";
    let forwardingAddressAmlApprovedLabel =  "This contact is the Primary Contact for this business and I confirm that we have approved the AML check for the updated forwarding address.";
    if(companyType === COMPANY_TYPE.POST_BOX) {
        primaryContactLabel = "Tick here to make this contact the Primary Contact for this post box.";
        forwardingAddressAmlApprovedLabel =  "This contact is the Primary Contact for this post box and I confirm that we have approved the AML check for the updated forwarding address.";
    }
    
    return (
        <Box sx={{ maxWidth: 1200 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={8} md={6}>
                    <Controller
                        name="title"
                        control={control}
                        defaultValue={defaultValues ? defaultValues.title : 0}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                select
                                label="Title"
                                variant="outlined"
                                margin="normal"
                                InputLabelProps={{
                                    style: { ...theme.inputLabelProps },
                                }}
                                fullWidth
                                size="small"
                            >
                                <MenuItem key="0" value={0}>&nbsp;</MenuItem>
                                <MenuItem key="1" value={1}>Mr</MenuItem>
                                <MenuItem key="2" value={2}>Mrs</MenuItem>
                                <MenuItem key="3" value={3}>Ms</MenuItem>
                                <MenuItem key="4" value={4}>Miss</MenuItem>
                                <MenuItem key="5" value={5}>Dr</MenuItem>
                            </TextField>
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={8} md={6}>
                    <Controller
                        name="phoneNumber"
                        control={control}
                        defaultValue={defaultValues ? defaultValues.phoneNumber : ""}
                        rules={{ required: 'Phone number is required' }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Phone *"
                                variant="outlined"
                                InputLabelProps={{
                                    style: { ...theme.inputLabelProps },
                                }}
                                margin="normal"
                                fullWidth
                                size="small"
                                error={!!formState.errors.phoneNumber}
                                helperText={formState.errors.phoneNumber ? formState.errors.phoneNumber.message : ''}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={8} md={6}>
                    <Controller
                        name="firstName"
                        control={control}
                        defaultValue={defaultValues ? defaultValues.firstName : ""}
                        rules={{ required: 'First name is required' }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="First Name *"
                                variant="outlined"
                                InputLabelProps={{
                                    style: { ...theme.inputLabelProps },
                                }}
                                margin="normal"
                                fullWidth
                                size="small"
                                error={!!formState.errors.firstName}
                                helperText={formState.errors.firstName ? formState.errors.firstName.message : ''}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={8} md={6}>
                    <Controller
                        name="mobileNumber"
                        control={control}
                        defaultValue={defaultValues ? defaultValues.mobileNumber : ""}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Mobile"
                                variant="outlined"
                                InputLabelProps={{
                                    style: { ...theme.inputLabelProps },
                                }}
                                margin="normal"
                                fullWidth
                                size="small"
                                error={!!formState.errors.mobileNumber}
                                helperText={formState.errors.mobileNumber ? formState.errors.mobileNumber.message : ''}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={8} md={6}>
                    <Controller
                        name="lastName"
                        control={control}
                        defaultValue={defaultValues ? defaultValues.lastName : ""}
                        rules={{ required: 'Last name is required' }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Last Name *"
                                variant="outlined"
                                InputLabelProps={{
                                    style: { ...theme.inputLabelProps },
                                }}
                                margin="normal"
                                fullWidth
                                size="small"
                                error={!!formState.errors.lastName}
                                helperText={formState.errors.lastName ? formState.errors.lastName.message : ''}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={8} md={6}>
                    <Controller
                        name="email"
                        control={control}
                        defaultValue={defaultValues ? defaultValues.email : ""}
                        rules={{
                            required: 'Email is required',
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: 'Invalid email address',
                            },
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Email *"
                                variant="outlined"
                                InputLabelProps={{
                                    style: { ...theme.inputLabelProps },
                                }}
                                margin="normal"
                                fullWidth
                                size="small"
                                error={!!formState.errors.email}
                                helperText={formState.errors.email ? formState.errors.email.message : ''}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={8} md={6} style={{display: companyType === COMPANY_TYPE.COMPANY ? 'block' : 'none' }}>
                    <Controller
                        name="businessRole"
                        control={control}
                        defaultValue={defaultValues ? defaultValues.businessRole : ""}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Role"
                                variant="outlined"
                                InputLabelProps={{
                                    style: { ...theme.inputLabelProps },
                                }}
                                margin="normal"
                                fullWidth
                                size="small"
                                error={!!formState.errors.businessRole}
                                helperText={formState.errors.businessRole ? formState.errors.email.businessRole : ''}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={8} md={6}>
                    <Controller
                        name="alternativeEmail"
                        control={control}
                        defaultValue={defaultValues ? defaultValues.alternativeEmail : ""}
                        rules={{
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: 'Invalid email address',
                            },
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Alternative Email"
                                variant="outlined"
                                InputLabelProps={{
                                    style: { ...theme.inputLabelProps },
                                }}
                                margin="normal"
                                fullWidth
                                size="small"
                                error={!!formState.errors.alternativeEmail}
                                helperText={formState.errors.alternativeEmail ? formState.errors.alternativeEmail.message : ''}
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Typography variant="h6" noWrap component="div" align={"left"} sx={{ mt: 2 }}>
                Address details
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={8} md={6}>
                    <Controller
                        name="address.addressLine1"
                        control={control}
                        defaultValue={defaultValues ? defaultValues.address.addressLine1 : ""}
                        rules={{ required: 'Address Line 1 is required' }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Address Line 1 *"
                                variant="outlined"
                                InputLabelProps={{
                                    style: { ...theme.inputLabelProps },
                                }}
                                margin="normal"
                                fullWidth
                                size="small"
                                onChange={(e) => checkIfPersonRequiresAmlApproval(e, field)}
                                error={!!formState.errors.address?.addressLine1}
                                helperText={formState.errors.address?.addressLine1 ? formState.errors.address.addressLine1.message : ''}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={8} md={6}>
                    <Controller
                        name="address.addressLine2"
                        control={control}
                        defaultValue={defaultValues ? defaultValues.address.addressLine2 : ""}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Address Line 2"
                                variant="outlined"
                                InputLabelProps={{
                                    style: { ...theme.inputLabelProps },
                                }}
                                margin="normal"
                                fullWidth
                                size="small"
                                onChange={(e) => checkIfPersonRequiresAmlApproval(e, field)}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={8} md={6}>
                    <Controller
                        name="address.locality"
                        control={control}
                        defaultValue={defaultValues ? defaultValues.address.locality : ""}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Town / City"
                                variant="outlined"
                                InputLabelProps={{
                                    style: { ...theme.inputLabelProps },
                                }}
                                margin="normal"
                                fullWidth
                                size="small"
                                onChange={(e) => checkIfPersonRequiresAmlApproval(e, field)}
                                error={!!formState.errors.address?.locality}
                                helperText={formState.errors.address?.locality ? formState.errors.address.locality.message : ''}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={8} md={6}>
                    <Controller
                        name="address.country"
                        control={control}
                        defaultValue={defaultValues ? defaultValues.address.country : "United Kingdom"}
                        rules={{ required: 'Country is required' }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Country *"
                                variant="outlined"
                                InputLabelProps={{
                                    style: { ...theme.inputLabelProps },
                                }}
                                margin="normal"
                                fullWidth
                                size="small"
                                onChange={(e) => checkIfPersonRequiresAmlApproval(e, field)}
                                error={!!formState.errors.address?.country}
                                helperText={formState.errors.address?.country ? formState.errors.address.country.message : ''}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={8} md={6}>
                    <Controller
                        name="address.postalCode"
                        control={control}
                        defaultValue={defaultValues ? defaultValues.address.postalCode : ""}
                        rules={{ required: 'Post Code is required' }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Post Code *"
                                variant="outlined"
                                InputLabelProps={{
                                    style: { ...theme.inputLabelProps },
                                }}
                                margin="normal"
                                fullWidth
                                size="small"
                                onChange={(e) => checkIfPersonRequiresAmlApproval(e, field)}
                                error={!!formState.errors.address?.postalCode}
                                helperText={formState.errors.address?.postalCode ? formState.errors.address.postalCode.message : ''}
                            />
                        )}
                    />
                </Grid>
            </Grid>

            <Controller
                name="marketingOptOut"
                control={control}
                defaultValue={defaultValues ? defaultValues.marketingOptOut : false}
                render={({ field }) => (
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox {...field} 
                                               checked={field.value}/>}
                            label="Tick this box if you would like to opt out of marketing communications from our closely selected partners."
                        />
                    </FormGroup>
                )}
            />

            <Controller
                name="isPrimaryContact"
                control={control}
                defaultValue={defaultValues ? defaultValues.isPrimaryContact : false}
                render={({ field }) => (
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox {...field} 
                                               checked={field.value}
                                               onChange={(e) => checkIfPersonRequiresAmlApproval(e, field)}
                                               disabled={defaultValues ? defaultValues.isPrimaryContact : false}
                            />}
                            label={primaryContactLabel}
                        />
                    </FormGroup>
                )}
            />
            <Controller
                name="forwardingAddressAmlApproved"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox {...field} checked={field.value} disabled={!forwardingAddressRequiresAmlApproval}
                                               onChange={(e) => {
                                                   // Update react-hook-form's form state
                                                   field.onChange(e.target.checked);

                                                   // Update local state - You might not need this anymore
                                                   setIsForwardingAddressAmlApproved(e.target.checked);
                                               }}
                            />}
                            label={forwardingAddressAmlApprovedLabel}
                        />
                    </FormGroup>
                    )}
                    />
        </Box>


    );

}