import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from "@mui/material/TextField";
import {
    Checkbox,
    CircularProgress,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle, FormControlLabel,
    Grid, InputAdornment,
    MenuItem,
    Paper,
    Stack
} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import Button from "@mui/material/Button";
import {enqueueSnackbar} from "notistack";
import {useContext, useEffect, useState} from "react";
import { Document, Page, pdfjs } from 'react-pdf';
import { SizeMe } from 'react-sizeme'
import {useNavigate, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import TransitionAlert from "../layout/TransitionAlert";
import mailService from "./MailService";
import AuthContext from "../api-authorization/AuthContext";
import {useTheme} from "@mui/material/styles";
import {ROLES} from "../enums";
import NavigationContext from "../layout/NavigationContext";
import Breadcrumb from "../layout/Breadcrumb";
import {FormGroup} from "reactstrap";
import {generateMailLabelXml} from "../labels/GenerateMailLabelXml";
import labelService from "../labels/LabelService";
import {generateMailInformationLabelXml} from "../labels/GenerateMailInformationLabelXml";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function UpdateMail() {
    const { histNavigate,back } = useContext(NavigationContext);
    const { antiForgeryToken, user, refreshUser } = useContext(AuthContext);
    const theme = useTheme();
    const { mailId } = useParams();
    const { handleSubmit, control, formState, setError,setValue } = useForm();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [isBusy, setBusy] = useState(true);
    const [mailData, setMailData] = useState();
    const navigate = useNavigate();
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    async function fetchData() {
        const result = await mailService.getMail(antiForgeryToken, mailId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        } else {
            let data = await result.json();
            if (result.ok) {
                return data;
            } else {
                enqueueSnackbar(data.description);
            }
        }
        return null;
    }

    useEffect(() => {
        fetchData().then(data => {
            setMailData(data);
            setBusy(false);
        });
    }, [])

    
    const handleFormSubmit  = async (formData) => {
        setBusy(true);
        formData.id = mailData.id;
        if(formData.postageFee === ""){
            formData.postageFee = null;
        }
        if(formData.handlingFee === ""){
            formData.handlingFee = null;
        }
        if(formData.requestToBePosted && (!formData.postageFee || !formData.handlingFee)) {
            setError("requestToBePosted", {
                type: "manual",
                message: "Postage and Handling fee cannot be blank if 'Request to be posted' is checked",
            });
            setBusy(false)
            return; // stop form submission, stay on form
        }
        
        const result = await mailService.updateMail(antiForgeryToken, formData);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar("Successfully updated mail item");
                const newData = await fetchData();
                setMailData(newData);
                //annoyingly the state variable isn't getting updated by the above line so we have to do it manually
                setValue('mailProcessStatus', newData.mailProcessStatus);
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setBusy(false);
    };

    const removeMail  = async () => {
        setBusy(true);
        const result = await mailService.removeMail(antiForgeryToken, mailData.id);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar("Successfully removed mail item");
                back();
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setDialogOpen(false);
        setBusy(false);
    };

    
    function downloadPDF(pdf) {
        const linkSource = `data:application/pdf;base64,${pdf}`;
        const downloadLink = document.createElement("a");
        const fileName = "mailItem.pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }
    
    const dateFormatter = (params) => {
        if(params === null){
            return "-";
        }
        const date = new Date(params);
        if (isNaN(date.getTime()) || date.getFullYear() <= 1) {
            return "None";
        }
        return date.toLocaleString('en-GB');
    };

    const printMailLabel = async () => {
        const result = await labelService.getMailLabel(antiForgeryToken, mailId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            const xml = generateMailLabelXml(data);
            let printers = window.dymo.label.framework.getPrinters();
            let connectedPrinter = printers.find(printer => printer.isConnected === true);
            if(!connectedPrinter){
                enqueueSnackbar("An error occured: No printers found");
                console.log(xml);
            }
            else {
                let label = window.dymo.label.framework.openLabelXml(xml);
                label.print(connectedPrinter.name);
            }

        } else {
            enqueueSnackbar(data.description);
        }
    };

    const printMailInformationLabel = async () => {
        const result = await labelService.getMailInformationLabel(antiForgeryToken, mailId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            const xml = generateMailInformationLabelXml(data);
            let printers = window.dymo.label.framework.getPrinters();
            let connectedPrinter = printers.find(printer => printer.isConnected === true);
            if(!connectedPrinter){
                enqueueSnackbar("An error occured: No printers found");
                console.log(xml);
            }
            else {
                let label = window.dymo.label.framework.openLabelXml(xml);
                label.print(connectedPrinter.name);
            }

        } else {
            enqueueSnackbar(data.description);
        }
    };


    let content;
    if (isBusy) {
        content = (
                <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                    <CircularProgress />
                </Box>
            );
    } else {
        let readonly = true;
        if(user.role === ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD){
            readonly = false;
        }
        content = (
            <>
                {(user.role === ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD || user.role === ROLES.CLIENT_ADMIN || user.role === ROLES.CLIENT_STANDARD) &&(
                    <Breadcrumb breadcrumbItems={mailData.breadcrumbItems}/>
                )}
                
                <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb: 2}}>
                    Company - {mailData.companyName}
                </Typography>
                <Box sx={{ maxWidth: 1200, mt:2 }}>
                    <TransitionAlert message={"NOTE:    As an Admin user, you can view, edit, download or remove mail items."}/>
                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={8} md={6} sx={{mt: 2}} >
                                <Paper style={{overflow: 'auto', padding: '10px'}} variant="outlined" >
                                    <SizeMe
                                        monitorHeight
                                        refreshRate={128}
                                        refreshMode={"debounce"}
                                        render={({ size }) => (
                                            <div>
                                                {
                                                    (mailData.content !== null) ? (
                                                    (mailData.fileType == "image/jpeg" || mailData.fileType == "image/png")
                                                        ?
                                                        <img style={{maxWidth: '100%', maxHeight: '100%'}}  src={`data:${mailData.fileType};base64,${mailData.content}`}/>
                                                        : (
                                                            <Document
                                                                file={`data:application/pdf;base64,${mailData.content}`}
                                                                onLoadSuccess={onDocumentLoadSuccess}
                                                                onLoadError={console.error}
                                                                onClick={() => downloadPDF(mailData.content)}
                                                            >
                                                                <Page width={size.width} pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false}/>
                                                            </Document>
                                                        )
                                                    ) :(
                                                        <i>No preview available</i>
                                                    )
                                                }
                                            </div>
                                        )}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={8} md={6} >
                                <Controller
                                    name="personName"
                                    control={control}
                                    defaultValue={mailData.personName ? mailData.personName : " "}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Person Name"
                                            variant="outlined"
                                            disabled={true}
                                            InputLabelProps={{
                                                style: { ...theme.inputLabelProps },
                                            }}
                                            margin="normal"
                                            fullWidth
                                            size="small"
                                        />
                                    )}
                                />
                                <Controller
                                    name="receivedDate"
                                    control={control}
                                    defaultValue={dateFormatter(mailData.receivedDate)}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Received Date"
                                            variant="outlined"
                                            disabled={true}
                                            InputLabelProps={{
                                                style: { ...theme.inputLabelProps },
                                            }}
                                            margin="normal"
                                            fullWidth
                                            size="small"
                                        />
                                    )}
                                />
                                <Controller
                                    name="mailProcessStatus"
                                    control={control}
                                    defaultValue={mailData.mailProcessStatus}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Status"
                                            variant="outlined"
                                            disabled={true}
                                            InputLabelProps={{
                                                style: { ...theme.inputLabelProps },
                                            }}
                                            margin="normal"
                                            fullWidth
                                            size="small"
                                        />
                                    )}
                                />
                                <Controller
                                    name="sentDate"
                                    control={control}
                                    defaultValue={dateFormatter(mailData.sentDate)}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Sent Date"
                                            variant="outlined"
                                            disabled={true}
                                            InputLabelProps={{
                                                style: { ...theme.inputLabelProps },
                                            }}
                                            margin="normal"
                                            fullWidth
                                            size="small"
                                        />
                                    )}
                                />
                                {
                                    user?.role === ROLES.TENANT_ADMIN
                                        ?
                                        (
                                            <Controller
                                                name="checkedInBy"
                                                control={control}
                                                defaultValue={mailData.checkedInBy}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        label="Checked In By"
                                                        variant="outlined"
                                                        disabled={true}
                                                        InputLabelProps={{
                                                            style: { ...theme.inputLabelProps },
                                                        }}
                                                        margin="normal"
                                                        fullWidth
                                                        size="small"
                                                    />
                                                )}
                                            />
                                        )
                                        : (
                                            <>
                                            </>
                                        )
                                }

                                <Controller
                                    name="rts"
                                    control={control}
                                    defaultValue={mailData.rts}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="RTS"
                                            variant="outlined"
                                            disabled={true}
                                            InputLabelProps={{
                                                style: { ...theme.inputLabelProps },
                                            }}
                                            margin="normal"
                                            fullWidth
                                            size="small"
                                        />
                                    )}
                                />
                                
                                <Controller
                                    name="mailType"
                                    control={control}
                                    defaultValue={mailData.mailType}
                                    rules={{ required: 'Mail type is required' }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            select
                                            label="Mail type *"
                                            variant="outlined"
                                            InputLabelProps={{
                                                style: { ...theme.inputLabelProps },
                                            }}
                                            margin="normal"
                                            fullWidth
                                            size="small"
                                            disabled={readonly}
                                            error={!!formState.errors.mailType}
                                            helperText={formState.errors.mailType ? formState.errors.mailType.message : ''}
                                        >
                                            <MenuItem key="1" value={1}>Royal Mail - Letter</MenuItem>
                                            <MenuItem key="2" value={2}>Royal Mail - Large Letter</MenuItem>
                                            <MenuItem key="3" value={3}>Royal Mail - Small Parcel</MenuItem>
                                            <MenuItem key="4" value={4}>Royal Mail - Medium Parcel</MenuItem>
                                            <MenuItem key="5" value={5}>Royal Mail - Large Parcel</MenuItem>
                                            <MenuItem key="6" value={6}>Companies House</MenuItem>
                                            <MenuItem key="7" value={7}>The pension Regulator</MenuItem>
                                            <MenuItem key="8" value={8}>HMRC</MenuItem>
                                            <MenuItem key="9" value={9}>Other - Special Delivery</MenuItem>
                                            <MenuItem key="10" value={10}>Other - Statutory Mail</MenuItem>
                                            <MenuItem key="11" value={11}>Printed Matter</MenuItem>
                                        </TextField>
                                    )}
                                />
                                <Stack direction="row" spacing={4} sx={{mt:2,mb:1}}>
                                    <Controller
                                        name="postageFee"
                                        control={control}
                                        defaultValue={mailData.postageFee?.toFixed(2)}
                                        readOnly={readonly}
                                        render={({field, fieldState: { error } }) => (
                                            <TextField
                                                {...field}
                                                onBlur={(e) => {
                                                    let value = parseFloat(e.target.value);
                                                    if (!isNaN(value)) {
                                                        if (value > 999.99) {
                                                            value = 999.99;
                                                        }
                                                        value = value.toFixed(2);
                                                    } else {
                                                        value = '';
                                                    }
                                                    field.onBlur(); // call to onBlur method from field object
                                                    field.onChange(value); // updating field value
                                                }}
                                                label="Postage"
                                                type="number"
                                                variant="outlined"
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                                }}
                                                sx={{width: '20%'}}
                                                InputLabelProps={{
                                                    style: { ...theme.inputLabelProps },
                                                }}
                                                margin="normal"
                                                size="small"
                                            />
                                        )}
                                    />

                                    <Controller
                                        name="handlingFee"
                                        control={control}
                                        defaultValue={mailData.handlingFee?.toFixed(2)}
                                        readOnly={readonly}
                                        render={({field, fieldState: { error } }) => (
                                            <TextField
                                                {...field}
                                                onBlur={(e) => {
                                                    let value = parseFloat(e.target.value);
                                                    if (!isNaN(value)) {
                                                        if (value > 999.99) {
                                                            value = 999.99;
                                                        }
                                                        value = value.toFixed(2);
                                                    } else {
                                                        value = '';
                                                    }
                                                    field.onBlur(); // call to onBlur method from field object
                                                    field.onChange(value); // updating field value
                                                }}
                                                label="Handling"
                                                type="number"
                                                variant="outlined"
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                                }}
                                                sx={{width: '20%'}}
                                                InputLabelProps={{
                                                    style: { ...theme.inputLabelProps },
                                                }}
                                                margin="normal"
                                                size="small"
                                            />
                                        )}
                                    />
                                </Stack>


                                
                                <Controller
                                    name="itemId"
                                    control={control}
                                    defaultValue={mailData.itemId ? mailData.itemId : " "}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Item Id"
                                            variant="outlined"
                                            disabled={true}
                                            InputLabelProps={{
                                                style: { ...theme.inputLabelProps },
                                            }}
                                            disabled={readonly}
                                            margin="normal"
                                            fullWidth
                                            size="small"
                                        />
                                    )}
                                />
                                <Controller
                                    name="notes"
                                    control={control}
                                    defaultValue={mailData.notes ? mailData.notes : " "}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Notes"
                                            disabled={true}
                                            multiline
                                            rows={4}
                                            variant="outlined"
                                            InputLabelProps={{
                                                style: { ...theme.inputLabelProps },
                                            }}
                                            disabled={readonly}
                                            margin="normal"
                                            fullWidth
                                            size="small"
                                        />
                                    )}
                                />
                                {mailData.canBePosted && (
                                    <Controller
                                        name="requestToBePosted"
                                        control={control}
                                        defaultValue={mailData.postageRequested}
                                        disabled={mailData.hasBeenPosted}
                                        render={({ field }) => (
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={<Checkbox {...field} checked={field.value} />}
                                                    label="Request to be posted"
                                                />
                                                {formState.errors.requestToBePosted && (
                                                    <>
                                                        <br/>
                                                        <Typography variant="caption" color="error">
                                                            {formState.errors.requestToBePosted.message}
                                                        </Typography>
                                                    </>
                                                )}
                                            </FormGroup>
                                        )}
                                    />
                                )}
                            </Grid>
                        </Grid>

                        <Stack direction="row" spacing={2} marginTop={2}>
                            <Button variant="contained" color="secondary" onClick={(e) => {
                                back();
                            }}>
                                Back
                            </Button>
                            {readonly === false && (
                                <>
                                    <Button variant="contained" color="secondary" onClick={printMailLabel}>
                                        Print Label
                                    </Button>
                                    <Button variant="contained" color="secondary" onClick={printMailInformationLabel}>
                                        Print Info Label
                                    </Button>
                                    <Button variant="contained" color="secondary" onClick={handleDialogOpen}>
                                        Remove
                                    </Button>
                                    <Button variant="contained" color="secondary" type="submit" disabled={disableSubmit}>
                                        Update
                                    </Button>
                                </>
                            )}
                        </Stack>
                    </form>
                    <Dialog
                        open={dialogOpen}
                        onClose={handleDialogClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Warning"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you wish to remove this mail item?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDialogClose}>No</Button>
                            <Button onClick={removeMail} autoFocus>
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </>
        );
    }

    return (
        <>
            {content}
        </>
    );
}