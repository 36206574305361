import React, {useContext, useEffect, useState} from 'react';
import Button from "@mui/material/Button";
import {
    Typography, Grid, CircularProgress, Stack, CardActionArea, Card, Paper
} from "@mui/material";
import {enqueueSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import billingService from "../billing/BillingService";
import productService from "./ProductService";
import {BILLING_TABS, RESOURCE_TYPE, TAX_RATE} from "../enums";
import accountService from "../accounts/AccountService";
import companyService from "../companies/CompanyService";
import NavigationContext from "../layout/NavigationContext";

export default function Checkout({ antiForgeryToken, resourceType, resourceId, products, cart, refreshUser, user }) {
    const { histNavigate, back } = useContext(NavigationContext);
    const [paymentMethods, setPaymentMethods] = useState();
    const [resourceData, setResourceData] = useState();
    const [paymentMethodsLoading, setPaymentMethodsLoading] = useState(true);
    const [resourceLoading, setResourceLoading] = useState(true);
    const [isPurchasing, setIsPurchasing] = useState(false);
    const navigate = useNavigate();
    const [selectedPaymentMethodId, setSelectedPaymentMethodId] = React.useState("");

    const handlePaymentMethodClick = (id) => {
        setSelectedPaymentMethodId(id);
    };

    let UKPound = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
    });
    
    
    useEffect(() => {
        async function getCheckoutDetails() {
            const result = await billingService.listPaymentMethods(antiForgeryToken, resourceType, resourceId);
            if (result.status === 401) {
                refreshUser();
                navigate('/login');
            }
            else {
                let data = await result.json();
                if (result.ok) {
                    setPaymentMethods(data.cards);
                    setPaymentMethodsLoading(false);
                    const defaultCard = data.cards.find(card => card.default);
                    if (defaultCard) {
                        setSelectedPaymentMethodId(defaultCard.id);
                    }
                } else {
                    enqueueSnackbar(data.description);
                }
            }
        }

        async function getResourceData() {
            let result;
            if(resourceType == RESOURCE_TYPE.ACCOUNT){
                result = await accountService.getAccount(antiForgeryToken, resourceId);
            }
            else{
                result = await companyService.getCompany(antiForgeryToken, resourceId);
            }

            if (result.status === 401) {
                refreshUser();
                navigate('/login');
            }
            else {
                let data = await result.json();
                if (result.ok) {
                    setResourceData(data);
                    setResourceLoading(false);
                } else {
                    enqueueSnackbar(data.description);
                }
            }
        }

        getCheckoutDetails();
        getResourceData();
    }, [])

    const purchaseNow  = async () => {
        setIsPurchasing(true);
        const result = await productService.addProductsToCustomer(antiForgeryToken, resourceType, resourceId, cart, selectedPaymentMethodId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar("Successfully added product to customer");
                back();
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setIsPurchasing(false);
    };

    let content;
    if (resourceLoading || paymentMethodsLoading || isPurchasing) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress/>
            </Box>
        );
    } else {
        let subTotal = 0;
        let vatTotal = 0;
        let total;
        
        let cartItems = products.map(product => {
            if(cart.includes(product.id)) {
                if(product.freeTrialDays == null) {
                    subTotal += product.price;
                    vatTotal += Number((product.price * TAX_RATE.UK).toFixed(2));
                }
                return (
                    <Box key={product.id} style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography sx={{mt: 2, mb: 2}} variant="basketItem">
                            {product.name}
                        </Typography>
                        <Typography sx={{mt: 2, mb: 2}} variant="basketItem" style={{ textAlign: 'right' }}>
                            {UKPound.format(product.freeTrialDays != null && product.freeTrialDays > 0 ? 0 : product.price)}
                        </Typography>
                    </Box>
                )
            } else {
                return null;
            }
        });
        total = (subTotal + vatTotal);
        
        let billingContent;
        
        if(!resourceData.clientBillingEnabled){
            billingContent = (
                <Box sx={{mt: 4, mb: 4}}>
                    <Stack direction="row" spacing={2} marginTop={2}>
                        <Button variant="contained" color="secondary" onClick={purchaseNow}>
                            Purchase now
                        </Button>
                    </Stack>
                </Box>
            );
        }
        else if(paymentMethods.length > 0){

            let cardsContent = paymentMethods.map((paymentMethod) => {
                return (
                    <Card
                        sx={{ backgroundColor: selectedPaymentMethodId === paymentMethod.id ? 'grey.200' : 'white', mb:2 }}>
                        <CardActionArea  sx={{p:2}}
                                         onClick={() => handlePaymentMethodClick(paymentMethod.id)}>
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">
                                        <strong>{paymentMethod.brand} ending in **** {paymentMethod.last4}</strong>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">
                                        <strong>Expiry {paymentMethod.expiryDate}</strong>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle2">
                                        Billing address: <strong>{paymentMethod.address}</strong>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle2" sx={{textAlign:"right"}}>
                                        {paymentMethod.default ? <strong>Default</strong> : null}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardActionArea>
                    </Card>
                )
            });

            billingContent =  (
                <Box sx={{mt: 4}}>
                    <Paper sx={{maxHeight: 400, overflow: 'auto',boxShadow:"none"}}>
                        {cardsContent}
                    </Paper>
                    <Stack direction="row" spacing={2} marginTop={6}>
                        <Button variant="contained" color="secondary" onClick={purchaseNow} disabled={selectedPaymentMethodId === ""}>
                            Purchase now
                        </Button>
                        <Button variant="contained" color="secondary" onClick={(e) => {
                            if(resourceType == RESOURCE_TYPE.COMPANY || resourceType == RESOURCE_TYPE.POST_BOX){
                                histNavigate(`/Billing/Update/${RESOURCE_TYPE.ACCOUNT}/${resourceData.accountId}/${BILLING_TABS.PAYMENT_METHOD}`,'Update Payment Method');
                            }
                            else{
                                histNavigate(`/Billing/Update/${resourceType}/${resourceId}/${BILLING_TABS.PAYMENT_METHOD}`,'Update Payment Method');
                            }
                        }}>
                            Update card
                        </Button>
                    </Stack>
                    <Typography variant="subtitle2" sx={{mt: 4, maxWidth: 400}}>
                        By clicking &quot;Purchase now&quot; you agree to our terms and conditions and privacy policy
                    </Typography>
                </Box>
            );
        }
        else{
            billingContent = (
                <Box sx={{mt: 4, mb: 4}}>
                    <Typography variant="subtitle2">
                        No default payment method found
                    </Typography>
                    <Stack direction="row" spacing={2} marginTop={2}>
                        <Button variant="contained" color="secondary" onClick={(e) => {
                            if(resourceType == RESOURCE_TYPE.COMPANY || resourceType == RESOURCE_TYPE.POST_BOX){
                                histNavigate(`/Billing/Update/${RESOURCE_TYPE.ACCOUNT}/${resourceData.accountId}/${BILLING_TABS.PAYMENT_METHOD}`,'Update Payment Method');
                            }
                            else{
                                histNavigate(`/Billing/Update/${resourceType}/${resourceId}/${BILLING_TABS.PAYMENT_METHOD}`,'Update Payment Method');
                            }
                        }}>
                            Update card
                        </Button>
                    </Stack>
                </Box>
            );
        }
        
        let headerDetails = ``;
        if(resourceType == RESOURCE_TYPE.ACCOUNT){
            headerDetails = `account - ${resourceData.email}`;
        }
        else if(resourceType == RESOURCE_TYPE.COMPANY){
            headerDetails = `company - ${resourceData.name}`;
        }
        else if(resourceType == RESOURCE_TYPE.POST_BOX){
            headerDetails = `post box - ${resourceData.postBoxNumber}`;
        }
        
        content = (
            <Box sx={{minHeight:500, maxWidth:1400}}>
                <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb: 2}}>
                        Checkout for {headerDetails}
                </Typography>
                <Grid container spacing={2} marginTop={2}>
                    <Grid item xs={12} sm={8} md={6}>
                        <Typography variant="basketSubHeader" sx={{mt: 2, mb: 2}} noWrap component="div" align={"left"}>
                            Payment details
                        </Typography>
                        <Divider sx={{borderColor: "#b8c4c4", borderWidth:"1px"}} />
                        {billingContent}
                    </Grid>
                    <Grid item xs={12} sm={8} md={6}>
                        <Typography variant="basketSubHeader" sx={{mt: 2, mb: 2}} noWrap component="div" align={"left"}>
                            Basket
                        </Typography>
                        <Divider sx={{borderColor: "#b8c4c4", borderWidth:"1px"}} />
                        {cartItems}
                        <Divider sx={{borderColor: "#b8c4c4", borderWidth:"1px"}} />
                        <div  style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography style={{ textAlign: 'left' }} sx={{mt: 2}}  variant="basketItem">
                                Subtotal
                            </Typography>
                            <Typography style={{ textAlign: 'right' }} sx={{mt: 2}} variant="basketItem">
                                {UKPound.format(subTotal)}
                            </Typography>
                        </div>
                        <div  style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography style={{ textAlign: 'left' }} sx={{mb: 2}}  variant="basketItem">
                                VAT
                            </Typography>
                            <Typography style={{ textAlign: 'right' }} sx={{mb: 2}} variant="basketItem">
                                {UKPound.format(vatTotal)}
                            </Typography>
                        </div>
                        <div  style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography style={{ textAlign: 'left' }} variant="basketSubHeader">
                                Total
                            </Typography>
                            <Typography style={{ textAlign: 'right' }} variant="basketSubHeader">
                                {UKPound.format(total)}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    return (
        <>
            {content}
        </>
    );
}