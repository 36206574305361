import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from "@mui/material/TextField";
import {
    CircularProgress,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    Grid,
    InputAdornment,
    MenuItem,
    Paper,
    Stack
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import {Controller, useForm} from "react-hook-form";
import Button from "@mui/material/Button";
import {enqueueSnackbar} from "notistack";
import {useContext, useEffect, useRef, useState} from "react";
import { Document, Page } from 'react-pdf';
import CompanySearchResultsSideBar from "./companySearchResultsSideBar";
import {useNavigate, useParams} from "react-router-dom";
import {
    ArrowBackIos,
    ArrowForwardIos, QuestionMark,
    ZoomIn,
    ZoomOut
} from "@mui/icons-material";
import scannedMailService from "./ScannedMailService";
import Typography from "@mui/material/Typography";
import AuthContext from "../api-authorization/AuthContext";
import companyService from "../companies/CompanyService";
import Divider from "@mui/material/Divider";
import mailService from "../mail/MailService";
import {generateMailLabelXml} from "../labels/GenerateMailLabelXml";
import {useTheme} from "@mui/material/styles";
import labelService from "../labels/LabelService";
import {generateMailInformationLabelXml} from "../labels/GenerateMailInformationLabelXml";
import Drawer from "@mui/material/Drawer";

export default function ProcessPreSortMail() {
    const theme = useTheme();
    const {antiForgeryToken, refreshUser} = useContext(AuthContext);
    const {handleSubmit, control, formState, setValue,trigger} = useForm();
    const { scanBatchId} = useParams();
    
    const [isBusy, setBusy] = useState(true);
    const [companySearchLoading, setCompanySearchLoading] = React.useState(false);
    const [mailItemsFound, setMailItemsFound] = useState(false);
    const [companySearchTerm, setCompanySearchTerm] = useState("");
    const [personSearchTerm, setPersonSearchTerm] = useState("");
    const [selectedCompanyId, setSelectedCompanyId] = useState("");
    const [selectedPersonName, setSelectedPersonName] = useState("");
    const [canSelectedCompanyCheckInMail, setCanSelectedCompanyCheckInMail] = useState(false);
    const [selectedMailType, setSelectedMailType] = useState(0);

    
    const [displayNoCompanySelectedError, setDisplayNoCompanySelectedError] = useState(false);
    const [unprocessedMailData, setUnprocessedMailData] = useState([])
    const [selectedRowId, setSelectedRowId] = useState("");

    const [lockedByOtherUsersCount, setLockedByOtherUsersCount] = useState(0);
    const [disableActions, setDisableActions] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    let defaultScale = 1.6;
    const [scale, setScale] = useState(defaultScale);
    const navigate = useNavigate();

    const [rtsDialogOpen, setRtsDialogOpen] = React.useState(false);
    const [unknownDialogOpen, setUnknownDialogOpen] = React.useState(false);
    const expressCheckInButtonRef = useRef(null);
    const nextButtonRef = useRef(null);
    const [infoDrawerOpen, setInfoDrawerOpen] = useState(false);

    const handleInfoDrawerOpen = () => {
        setInfoDrawerOpen(true);
    };

    const handleInfoDrawerClose = () => {
        setInfoDrawerOpen(false);
    };

    const onDocumentLoadSuccess = ({numPages}) => {
        setNumPages(numPages);
    };

    const handleRtsDialogOpen = () => {
        setRtsDialogOpen(true);
    };

    const handleRtsDialogClose = () => {
        setRtsDialogOpen(false);
    };

    const handleUnknownDialogOpen = () => {
        setUnknownDialogOpen(true);
    };

    const handleUnknownDialogClose = () => {
        setUnknownDialogOpen(false);
    };

    let tooltipMsg = (
        <>
            <p>Once a search result has been selected and is highlighted in green, there are multiple functions a user can select to process the mail item.</p>

            <p><b>Check-in</b> – records a mail item against the search result and allocates the mail item a storage slot.</p>

            <p><b>Express Check-in</b> – records a mail item against the search result selected. Mail is checked-in and out immediately and a label is printed.</p>

            <p><b>Next</b> – does not record a mail item, simply moves to the next image in the queue and deletes the prior image.</p>

            <p><b>Unknown</b> – Where the mail item cannot be identified the user can select the ‘Unknown’ button.</p>

            <p><b>RTS</b> – this button will print an RTS label for the mail item, deletes the current mail envelope image and moves to the next one in the queue.</p>

            <p><b>Postage & Handling</b> – Prior to selecting “Express Check-in” values entered here will be recorded against the mail item.</p>
        </>
    );
    
    async function setPostageFee(postageFee){
        setValue("postageFee", formatFee(postageFee));
    }
    
    async function setHandlingFee(handlingFee){
        setValue("handlingFee", formatFee(handlingFee));
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            const targetTagName = document.activeElement.tagName.toLowerCase()
            if ((targetTagName === 'input' || targetTagName === 'textarea') && document.activeElement.type !== 'button') {
                return; // Ignore if the user in typing context
            }

            // Use Space for express check in and Enter for next
            if (event.code === "Enter") {
                if (expressCheckInButtonRef.current) {
                    expressCheckInButtonRef.current.dispatchEvent(new MouseEvent('click', { bubbles: true }));
                    event.preventDefault();
                }
            }

            if (event.code === "Space") {
                if (nextButtonRef.current) {
                    nextButtonRef.current.dispatchEvent(new MouseEvent('click', { bubbles: true }));
                    event.preventDefault();
                }
            }
        };
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    function formatFee(valueToFormat) {
        let value = parseFloat(valueToFormat);
        if (!isNaN(value)) {
            if (value > 999.99) {
                value = 999.99;
            }
            value = value.toFixed(2);
        } else {
            value = '';
        }
        return value;
    }
    

    async function fetchData() {
        setDisableActions(true);
        setBusy(true);
        const result = await scannedMailService.getUnprocessedPreSortMail(antiForgeryToken,scanBatchId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                if(data.messageFound === true) {
                    setDisplayNoCompanySelectedError(false);
                    setDisableActions(false);
                    setMailItemsFound(true);
                    setUnprocessedMailData(data);
                    setPageNumber(1);
                    setCompanySearchTerm("");
                    setPersonSearchTerm("");
                    setScale(defaultScale);
                    setValue("postageFee", "");
                    setValue("handlingFee", "");

                    if(data.possibleCompanies && data.possibleCompanies.length > 0 && data.possibleCompanies[0].levenshteinDistance === 0){
                        setSelectedCompanyId(data.possibleCompanies[0].companyId);
                        setSelectedRowId(data.possibleCompanies[0].id);
                        setSelectedPersonName(data.possibleCompanies[0].personName == null ? "" : data.possibleCompanies[0].personName);
                        if (data.possibleCompanies[0].mailForwardingType !== 0) {
                            setValue("postageFee", data.possibleCompanies[0].clientDefaultMailPostageFee);
                            setValue("handlingFee", data.possibleCompanies[0].clientDefaultMailHandlingFee);
                        }
                        else
                        {
                            setPostageFee("");
                            setHandlingFee("");
                        }
                        setCanSelectedCompanyCheckInMail(data.possibleCompanies[0].canCheckInMail);
                    }
                    else{
                        setSelectedCompanyId("");
                        setSelectedRowId("");
                        setSelectedPersonName("");
                    }
                }
                else{
                    setDisableActions(false);
                    setMailItemsFound(false);
                    setLockedByOtherUsersCount(data.lockedByOtherUsersCount);
                }
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setBusy(false);
    }

    useEffect(() => {
        setBusy(true);
        fetchData();
    }, [])


    const createUnknownScannedMail = async () => {
        setDisableActions(true);
        const result = await scannedMailService.createUnknownScannedMail(antiForgeryToken, unprocessedMailData.messageId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar("Successfully marked pre-sort mail as unknown");
                fetchData();
            } else {
                enqueueSnackbar(data.description);
            }
        }
        handleUnknownDialogClose();
        setDisableActions(false);
    };

    const submitPreSortMail  = async () => {
        setDisableActions(true);
        let preSortRequest = {
            messageId: unprocessedMailData.messageId
        };
        const result = await scannedMailService.submitPreSortMail(antiForgeryToken, preSortRequest);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar("Successfully processed pre-sort mail item");
                fetchData();
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setDisableActions(false);
    };
    
    const printRtsLabel = async (mailId) => {
        const result = await labelService.getRtsLabel(antiForgeryToken, mailId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            const xml = generateMailInformationLabelXml(data);
            let printers = window.dymo.label.framework.getPrinters();
            let connectedPrinter = printers.find(printer => printer.isConnected === true);
            if(!connectedPrinter){
                enqueueSnackbar("An error occured: No printers found");
                console.log(xml);
            }
            else {
                let label = window.dymo.label.framework.openLabelXml(xml);
                label.print(connectedPrinter.name);
            }

        } else {
            enqueueSnackbar(data.description);
        }
    }

    const rtsMail = async () => {
        setDisableActions(true);
        let rtsMailRequest = {
            messageId: unprocessedMailData.messageId,
            companyId: selectedCompanyId ? selectedCompanyId : null,
            personName: selectedPersonName,
            mailType: selectedMailType
        }
        const result = await scannedMailService.rtsScannedMail(antiForgeryToken, rtsMailRequest);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                await printRtsLabel(data);
                enqueueSnackbar("Successfully marked pre-sort mail as RTS");
                fetchData();
            } else {
                enqueueSnackbar(data.description);
            }
        }
        handleRtsDialogClose();
        setDisableActions(false);
    };


    const checkInMail = async (data) => {
        if(selectedCompanyId === ""){
            setDisplayNoCompanySelectedError(true);
            return;
        }
        if(canSelectedCompanyCheckInMail === false){
            enqueueSnackbar("Cannot check in mail for this company");
            return;
        }
        setDisableActions(true);
        const formData = new FormData();
        formData.append('FileUpload', null);
        formData.append('CompanyId', selectedCompanyId);
        formData.append('PersonName', selectedPersonName);
        formData.append("MailType", selectedMailType);
        formData.append("ScanBatchId", scanBatchId);
        formData.append("HandlingFee", data.handlingFee);
        formData.append("PostageFee", data.postageFee);
        const result = await mailService.checkInMail(antiForgeryToken, formData);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar(`Successfully checked in a '${data.mailType}' mail for ${data.companyName}`);
                submitPreSortMail();
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setDisableActions(false);
    };
    
    const checkInAndCheckoutMail = async (data) => {
        if(selectedCompanyId === ""){
            setDisplayNoCompanySelectedError(true);
            return;
        }
        if(canSelectedCompanyCheckInMail === false){
            enqueueSnackbar("Cannot express checkout mail for this company");
            return;
        }
        setDisableActions(true);
        const formData = new FormData();
        formData.append('CompanyId', selectedCompanyId);
        formData.append('PersonName', selectedPersonName);
        //Default to other - statutory mail
        formData.append("MailType",selectedMailType);
        formData.append("ScanBatchId", scanBatchId);
        formData.append("HandlingFee", data.handlingFee);
        formData.append("PostageFee", data.postageFee);
        const result = await mailService.checkInAndCheckoutMail(antiForgeryToken, formData);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                enqueueSnackbar(`Successfully checked in and checked out a '${data.mailType}' mail for ${data.companyName}`);
                /* Temporarily disabling print labels here
                try{
                    printLabel(data.mailId);
                    if(data.printInformationLabel){
                        printMailInformationLabel(data.mailId);
                    }
                }
                catch (e) {
                    enqueueSnackbar("An error occured: No printers found");
                }*/
                submitPreSortMail();
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setDisableActions(false);
    };

    const printLabel = async (mailId) => {
        const result = await labelService.getMailLabel(antiForgeryToken, mailId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            const xml = generateMailLabelXml(data);
            let printers = window.dymo.label.framework.getPrinters();
            let connectedPrinter = printers.find(printer => printer.isConnected === true);
            if(!connectedPrinter){
                enqueueSnackbar("An error occured: No printers found");
                console.log(xml);
            }
            else {
                let label = window.dymo.label.framework.openLabelXml(xml);
                label.print(connectedPrinter.name);
            }

        } else {
            enqueueSnackbar(data.description);
        }
    };

    const printMailInformationLabel = async (mailId) => {
        const result = await labelService.getMailInformationLabel(antiForgeryToken, mailId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            const xml = generateMailInformationLabelXml(data);
            let printers = window.dymo.label.framework.getPrinters();
            let connectedPrinter = printers.find(printer => printer.isConnected === true);
            if(!connectedPrinter){
                enqueueSnackbar("An error occured: No printers found");
                console.log(xml);
            }
            else {
                let label = window.dymo.label.framework.openLabelXml(xml);
                label.print(connectedPrinter.name);
            }

        } else {
            enqueueSnackbar(data.description);
        }
    };

    const handleCompanySearch = async(event) => {
        event.preventDefault();
        setCompanySearchLoading(true);
        let searchRequest = {
            companySearchTerm: companySearchTerm === "" ? null : companySearchTerm,
            personSearchTerm: personSearchTerm === "" ? null : personSearchTerm
        }
        const result = await companyService.findCompaniesMailSettings(antiForgeryToken, searchRequest);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            unprocessedMailData.possibleCompanies = data.companies
        } else {
            enqueueSnackbar(data.description);
        }
        setSelectedCompanyId("");
        setSelectedRowId("");
        setSelectedPersonName("");
        setCompanySearchLoading(false);
    };
    const handlePageForward = () =>{
        if(pageNumber < numPages){
            setPageNumber(pageNumber + 1);
        }
    }
    const handlePageBack = () =>{
        if(pageNumber > 1){
            setPageNumber(pageNumber - 1);
        }
    }

    let companyResultsContent = (
        <></>
    );


    if(companySearchLoading){
        companyResultsContent = (
            <Box display="flex" justifyContent="center" alignItems="center" height="20vh">
                <CircularProgress />
            </Box>
        );
    }
    else {
        companyResultsContent = (
            <CompanySearchResultsSideBar
                companies={unprocessedMailData.possibleCompanies}
                setSelectedCompanyId={setSelectedCompanyId}
                setDisplayNoCompanySelectedError={setDisplayNoCompanySelectedError}
                selectedCompanyId={selectedCompanyId}
                selectedRowId={selectedRowId}
                setSelectedRowId={setSelectedRowId}
                setSelectedPersonName={setSelectedPersonName}
                setPostageFee={setPostageFee}
                setHandlingFee={setHandlingFee}
                setCanSelectedCompanyCheckInMail={setCanSelectedCompanyCheckInMail}
                selectedMailType={selectedMailType}
            />
        );

    }
    
    let content;
    if (isBusy) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else if (mailItemsFound === false) {
        content = (
            <Box>
                <Typography>Batch completed</Typography>
                <br/>
                <Stack direction="row" spacing={2} marginTop={2}>
                    <Button variant="contained" color="secondary"
                            disabled={disableActions || lockedByOtherUsersCount < 1} onClick={() => navigate(`/Mail/PreSortMailBatches`)}>
                        Batch List
                    </Button>
                </Stack>
            </Box>
        );
    } else {
        content = (
            <Box sx={{ maxHeight: 800 }}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb:4}} flexGrow={1}>
                        Batch: {unprocessedMailData.scanBatchTitle}
                    </Typography>
                    <Box>
                        <IconButton onClick={handleInfoDrawerOpen}
                                    sx={{
                                        backgroundColor: theme.palette.primary.main,
                                        color: "white",
                                        '&:hover': {
                                            color: theme.palette.primary.main
                                        }
                                    }}
                        >
                            <QuestionMark/>
                        </IconButton>
                        <Drawer
                            anchor="right"
                            open={infoDrawerOpen}
                            onClose={handleInfoDrawerClose}
                        >
                            <Box
                                sx={{
                                    width: 800,
                                    height: "100%",
                                    bgcolor: theme.palette.secondary.main,
                                    color: theme.palette.text.primary,
                                    padding: 4,
                                }} // Set the width of your Drawer
                                role="presentation"
                                onClick={handleInfoDrawerClose}
                                onKeyDown={handleInfoDrawerClose}
                            >
                                <h2>Pre-Sort Mail</h2>
                                <br/>
                                Once a search result has been selected and is highlighted in green, there are multiple
                                functions a user can select to process the mail item.
                                <br/>
                                <br/>
                                <strong>Check-In</strong> – records a mail item against the search result. The mail
                                image is not retained and you are unable to return to this mail item.
                                <br/>
                                <br/>
                                <strong>Express Checkout</strong> – records a mail item against the search result
                                selected. Mail is checked-in and out immediately and a label is printed.
                                <br/>
                                <br/>
                                <strong>Next</strong> – does not record a mail item, simply moves to the next mail item
                                in the queue and deletes the prior mail item image.
                                <br/>
                                <br/>
                                <strong>Unknown</strong> – records the mail item as “Unknown” and adds the mail item to
                                the unknown batch for the current day.
                                <br/>
                                <br/>
                                <strong>RTS</strong> – this button will print an RTS label for the mail item, deletes
                                the current mail image and moves to the next one in the queue.
                                <br/>
                                <br/>
                                <strong>Postage & Handling</strong> – Prior to selecting “Express Checkout” values
                                entered here will be
                                recorded against the mail item.
                                <br/>
                                <br/>
                                <Button onClick={handleInfoDrawerClose} variant="contained"
                                        color="secondary">Close</Button>
                            </Box>
                        </Drawer>
                    </Box>
                </Stack>
                <Grid container spacing={2} sx={{pr:2}}>
                    <Grid item xs={12} sm={8} md={6}>
                        <form onSubmit={handleCompanySearch}>
                            <TextField
                                id="search-bar"
                                className="text"
                                value={companySearchTerm}
                                onChange={(e) => {
                                    setCompanySearchTerm(e.target.value.toUpperCase());
                                }}
                                sx={{width: '100%', mt:6}}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton type="submit">
                                                <SearchIcon/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                label="Company & Post Box Search"
                                variant="outlined"
                                size="small"
                            />
                            <TextField
                                id="search-bar"
                                className="text"
                                value={personSearchTerm}
                                onChange={(e) => {
                                    setPersonSearchTerm(e.target.value.toUpperCase());
                                }}
                                sx={{mt: 5, mb: 2, width: '100%'}}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton type="submit">
                                                <SearchIcon/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                label="People Search"
                                variant="outlined"
                                size="small"
                            />
                        </form>
                        <Paper style={{overflow: 'auto', padding: '20px', maxHeight: "65vh", minHeight: "65vh"}}
                               variant="outlined">
                            <Stack
                                direction="row"
                                justifyContent="flex-end" 
                                style={{position: 'sticky', top: 2, zIndex: 1}}
                                spacing={2}
                            >
                                <Button variant="contained" color="secondary" disabled={scale <= 0.5} onClick={() => setScale(oldScale => Math.max(oldScale - 0.3, 0.3))}>
                                    <ZoomOut sx={{fontSize: 22}}/>
                                </Button>
                                <Button variant="contained" color="secondary" disabled={scale >= 2} key="zoomIn" onClick={() => setScale(oldScale => oldScale + 0.3)}>
                                    <ZoomIn sx={{fontSize: 22}}/>
                                </Button>
                            </Stack>
                            <Document
                                file={`data:application/pdf;base64,${unprocessedMailData.content}`}
                                onLoadSuccess={onDocumentLoadSuccess}
                                onLoadError={console.error}
                            >
                                <Page scale={scale} pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false}/>
                            </Document>
                        </Paper>
                        <div style={{color: "gray"}}>
                            <center>
                                <IconButton onClick={handlePageBack}>
                                    <ArrowBackIos/>
                                </IconButton>
                                Page {pageNumber} of {numPages}
                                <IconButton onClick={handlePageForward}>
                                    <ArrowForwardIos/>
                                </IconButton>
                            </center>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={8} md={6}>
                        <form>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: "flex-end",
                                    width:"100%",
                                    mb:2
                                }}
                            >
                                <Typography sx={{
                                    fontStyle: "italic",
                                    pt: 1,
                                }}>{unprocessedMailData.scannedMailCount === 1 ? `${unprocessedMailData.scannedMailCount} item remaining` : `${unprocessedMailData.scannedMailCount} items remaining`}</Typography>
                            </Box>
                            <Grid container spacing={10}>
                                <Grid item xs={7} sm={7} md={7}>
                                    <Stack direction="row" spacing={2}>
                                        <Controller
                                            name="mailType"
                                            control={control}
                                            defaultValue={selectedMailType}
                                            rules={{required: 'Mail type is required'}}
                                            render={({field}) => (
                                                <TextField
                                                    {...field}
                                                    select
                                                    label="Mail Type *"
                                                    variant="outlined"
                                                    margin="normal"
                                                    size="small"
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        setSelectedMailType(e.target.value);
                                                    }}
                                                    fullWidth={true}
                                                    error={!!formState.errors.mailType}
                                                    helperText={formState.errors.mailType ? formState.errors.mailType.message : ''}
                                                >
                                                    <MenuItem key="0" value={0}>&nbsp;</MenuItem>
                                                    <MenuItem key="1" value={1}>Royal Mail - Letter</MenuItem>
                                                    <MenuItem key="6" value={6}>Companies House</MenuItem>
                                                    <MenuItem key="8" value={8}>HMRC</MenuItem>
                                                    <MenuItem key="1" value={1}>Royal Mail - Letter</MenuItem>
                                                    <MenuItem key="7" value={7}>The Pension Regulator</MenuItem>
                                                    <MenuItem key="9" value={9}>Other - Special Delivery</MenuItem>
                                                    <MenuItem key="10" value={10}>Other - Statutory Mail</MenuItem>
                                                    <MenuItem key="11" value={11}>Printed Matter</MenuItem>
                                                </TextField>
                                            )}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={5} sm={5} md={5}>
                                    <Stack direction="row" spacing={2} justifyContent="flex-end">
                                        <Button variant="contained" color="secondary"
                                                disabled={disableActions} onClick={submitPreSortMail}
                                                ref={nextButtonRef}
                                                sx={{ml:2}}>
                                            Next
                                        </Button>
                                        <Button variant="contained" color="secondary"
                                                disabled={disableActions} onClick={handleUnknownDialogOpen}
                                                sx={{ml:2}}
                                        >
                                            Unknown
                                        </Button>
                                        <Button variant="contained" color="secondary"
                                                disabled={disableActions} onClick={handleRtsDialogOpen}
                                                sx={{ml:2}}>
                                            RTS
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Divider sx={{mt: 2.5, mb: 2.5, borderBottomWidth: 1}}/>
                            <Grid container spacing={10}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Stack direction="row" spacing={2}>
                                        <Controller
                                            name="postageFee"
                                            control={control}
                                            defaultValue={""}
                                            render={({field, fieldState: {error}}) => (
                                                <TextField
                                                    {...field}
                                                    onBlur={(e) => {
                                                        let value = parseFloat(e.target.value);
                                                        value = formatFee(value);
                                                        field.onBlur();
                                                        field.onChange(value);
                                                    }}
                                                    label="Postage"
                                                    type="number"
                                                    variant="outlined"
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                                    }}
                                                    InputLabelProps={{
                                                        style: {...theme.inputLabelProps},
                                                    }}
                                                    margin="normal"
                                                    size="small"
                                                />
                                            )}
                                        />
                                        <Controller
                                            name="handlingFee"
                                            control={control}
                                            defaultValue={""}
                                            render={({field}) => (
                                                <TextField
                                                    {...field}
                                                    onBlur={(e) => {
                                                        let value = parseFloat(e.target.value);
                                                        value = formatFee(value);
                                                        field.onBlur();
                                                        field.onChange(value);
                                                    }}
                                                    label="Handling"
                                                    type="number"
                                                    variant="outlined"
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                                    }}
                                                    InputLabelProps={{
                                                        style: {...theme.inputLabelProps},
                                                    }}
                                                    margin="normal"
                                                    size="small"
                                                />
                                            )}
                                        />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Stack direction="row" spacing={2} justifyContent="flex-end">
                                        <Button variant="contained"
                                                color="secondary"
                                                onClick={handleSubmit(checkInAndCheckoutMail)}
                                                disabled={disableActions}
                                                ref={expressCheckInButtonRef}
                                                sx={{ml:2}}>
                                            Express checkout
                                        </Button>
                                        <Button variant="contained" color="secondary"
                                                disabled={disableActions} onClick={handleSubmit(checkInMail)}>
                                            Check-In
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </form>
                        <Divider sx={{mt: 2.5, mb: 2.5, borderBottomWidth: 1}}/>
                        <span id={"noCompanySelectedErrorMessage"} style={{
                            display: displayNoCompanySelectedError === true ? 'block' : 'none',
                            color: "#d32f2f",
                            fontWeight: 400,
                            fontSize: "0.75rem",
                            lineHeight: 1.66,
                            textAlign: "left",
                            marginTop: "4px",
                            marginRight: "14px",
                            marginBottom: 0,
                            marginLeft: "14px"
                        }}>Please select a company</span>
                        <Paper sx={{
                            maxHeight: "62vh",
                            minHeight: "62vh",
                            overflow: 'auto',
                            border: displayNoCompanySelectedError === true ? '1px solid #D32F2F' : ''
                        }} variant="outlined">
                            {companyResultsContent}
                        </Paper>
                    </Grid>
                </Grid>

                <Dialog
                    open={rtsDialogOpen}
                    onClose={handleRtsDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Warning"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you wish to RTS this mail item?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleRtsDialogClose}>No</Button>
                        <Button onClick={(e) => {
                            e.preventDefault();
                            rtsMail();
                        }} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                
                <Dialog
                    open={unknownDialogOpen}
                    onClose={handleUnknownDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Warning"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you wish to mark this mail item as unknown?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleUnknownDialogClose}>No</Button>
                        <Button onClick={(e) => {
                            e.preventDefault();
                            createUnknownScannedMail();
                        }} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        );
    }

    return (
        <>
            {content}
        </>
    );

}