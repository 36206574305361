import React from 'react';
import {Grid, InputAdornment} from "@mui/material";
import {Controller} from "react-hook-form";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

export default function AddPostBoxDetails({formState, control}) {
    
    return (
        <Box sx={{ maxWidth: 600 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                    <Controller
                        name="postBoxNumber"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: 'Post box number is required',
                            maxLength : {value: 4, message: "Max length is 4"},
                            pattern: { value: /^[0-9]+$/, message: "Invalid box number" }
                        }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Post Box Number *"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                size="small"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">BM BOX</InputAdornment>,
                                }}
                                inputProps={{
                                    maxLength: 4,
                                }}
                                sx={{mb:0}}
                                error={!!formState.errors.postBoxNumber}
                                helperText={formState.errors.postBoxNumber ? formState.errors.postBoxNumber.message : ''}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Controller
                        name="postBoxName"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Post Box Name"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                size="small"
                                sx={{mb:0}}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} >
                    <Controller
                        name="previousNames"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Previous Names"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                size="small"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                        name="startDate"
                        control={control}
                        defaultValue={null}
                        render={({ field }) => (
                            <DatePicker
                                {...field}
                                label="Start Date"
                                slotProps={{ textField: { size: 'small', fullWidth: true } }}
                                format={"DD/MM/YYYY"}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                size="small"
                            />
                        )}
                    />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={12} md={12} sx={{mt:1}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Controller
                            name="endDate"
                            control={control}
                            defaultValue={null}
                            render={({ field }) => (
                                <DatePicker
                                    {...field}
                                    label="End Date"
                                    format={"DD/MM/YYYY"}
                                    slotProps={{ textField: { size: 'small', fullWidth: true } }}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    size="small"
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Grid>
                
            </Grid>
        </Box>
    );

}