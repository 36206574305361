import React, {useContext, useEffect, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {Checkbox, CircularProgress, FormControlLabel, MenuItem, Stack} from "@mui/material";
import {enqueueSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import userService from "./UserService";
import inputService from "../util/InputService";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import AuthContext from "../api-authorization/AuthContext";
import {FormGroup} from "reactstrap";

export default function MyProfile() {
    const { handleSubmit, control, formState } = useForm();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [userLoading, setUserLoading] = useState(true);
    const [userData, setUserData] = useState();
    const navigate = useNavigate();
    const theme = useTheme();
    const { antiForgeryToken, refreshUser} = useContext(AuthContext);
    
    const handleFormSubmit  = async (formData) => {
        setDisableSubmit(true);
        formData = inputService.trimFields(formData);
        const result = await userService.updateUserProfile(antiForgeryToken, formData);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                refreshUser();
                navigate(-1);
                enqueueSnackbar("Successfully updated profile");
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setDisableSubmit(false);
    };
    
    useEffect(() => {
        async function fetchData() {
            const result = await userService.getUserProfile(antiForgeryToken);
            if (result.status === 401) {
                refreshUser();
                navigate('/login');
            }
            else {
                let data = await result.json();
                if (result.ok) {
                    setUserData(data);
                    setUserLoading(false);
                } else {
                    enqueueSnackbar(data.description);
                }
            }
        }
        fetchData();
    }, [])

    let content;
    if (userLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        content = (
            <>
                <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb: 2}}>
                    My Profile
                </Typography>
                <Box sx={{ maxWidth: 900 }}>
                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                        <Box sx={{ maxWidth: 600 }}>
                            <Controller
                                name="title"
                                control={control}
                                defaultValue={userData.title}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        select
                                        label="Title"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                    >
                                        <MenuItem key="0" value={0}>&nbsp;</MenuItem>
                                        <MenuItem key="1" value={1}>Mr</MenuItem>
                                        <MenuItem key="2" value={2}>Mrs</MenuItem>
                                        <MenuItem key="3" value={3}>Ms</MenuItem>
                                        <MenuItem key="4" value={4}>Miss</MenuItem>
                                        <MenuItem key="5" value={5}>Dr</MenuItem>
                                    </TextField>
                                )}
                            />
                            <Controller
                                name="firstName"
                                control={control}
                                defaultValue={userData.firstName}
                                rules={{ required: 'First Name is required' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="First Name *"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.firstName}
                                        helperText={formState.errors.firstName ? formState.errors.firstName.message : ''}
                                    />
                                )}
                            />
                            <Controller
                                name="lastName"
                                control={control}
                                defaultValue={userData.lastName}
                                rules={{ required: 'Last Name is required' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Last Name *"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.lastName}
                                        helperText={formState.errors.lastName ? formState.errors.lastName.message : ''}
                                    />
                                )}
                            />
                            <Controller
                                name="phoneNumber"
                                control={control}
                                defaultValue={userData.phoneNumber}
                                rules={{ required: 'Phone Number is required' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Phone Number *"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.phoneNumber}
                                        helperText={formState.errors.phoneNumber ? formState.errors.phoneNumber.message : ''}
                                    />
                                )}
                            />
                            <Controller
                                name="email"
                                control={control}
                                defaultValue={userData.email}
                                rules={{
                                    required: 'Email is required',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: 'Invalid email address',
                                    },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Email *"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.email}
                                        helperText={formState.errors.email ? formState.errors.email.message : ''}
                                    />
                                )}
                            />
                        </Box>
                        <Controller
                            name="marketingOptOut"
                            control={control}
                            defaultValue={userData.marketingOptOut}
                            render={({ field }) => (
                                <FormGroup>
                                    <FormControlLabel
                                        sx={{mt:2, mb:2}}
                                        control={<Checkbox {...field} checked={field.value} />}
                                        label="Tick this box if you would like to opt out of marketing communications from our closely selected partners."
                                    />
                                </FormGroup>
                            )}
                        />
                        
                        <Stack direction="row" spacing={2} marginTop={2}>
                            <Button variant="contained" color="secondary" onClick={(e) => {
                                navigate(-1);
                            }}>
                                Back
                            </Button>
                            <Button variant="contained" color="secondary" type="submit" disabled={disableSubmit}>
                                Update
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </>
        );
    }

    return (
        <>
            {content}
        </>
    );
}