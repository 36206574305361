import React, {useContext, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {CircularProgress, Grid, MenuItem, Stack} from "@mui/material";
import {enqueueSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import accountService from "../accounts/AccountService";
import inputService from "../util/InputService";
import {useTheme} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import NavigationContext from "../layout/NavigationContext";
import userService from "../users/UserService";

export default function AccountDetailsConfirmation({getAccountData, accountData, antiForgeryToken, refreshUser, progressTab}) {
    const { back } = useContext(NavigationContext);
    const theme = useTheme();
    const { handleSubmit, control, formState } = useForm();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [isBusy, setBusy] = useState(false);
    const navigate = useNavigate();
    
    const accountDetailsChanged = (formData) => {
        if(formData.title != accountData.title ||
            formData.firstName != accountData.firstName ||
            formData.lastName != accountData.lastName ||
            formData.phoneNumber != accountData.phoneNumber ||
            formData.mobileNumber != accountData.mobileNumber ||
            formData.email != accountData.email) {
            return true;
        }
        return false;
    }

    const handleFormSubmit  = async (formData) => {
        setDisableSubmit(true);
        formData = inputService.trimFields(formData);
        formData.id = accountData.id;
        formData.clientId = accountData.clientId;
        if(accountDetailsChanged(formData)) {
            const result = await accountService.updateAccount(antiForgeryToken, formData);
            if (result.status === 401) {
                refreshUser();
                navigate('/login');
            }
            else {
                let data = await result.json();
                if (result.ok) {
                    await getAccountData();
                    enqueueSnackbar("Successfully updated account");
                } else {
                    enqueueSnackbar(data.description); // Assuming 'error' property in data
                }
                const getProfileResult = await userService.getUserProfile(antiForgeryToken);
                let getProfileData = await getProfileResult.json();
                if (result.ok) {
                    getProfileData.title = formData.title;
                    getProfileData.firstName = formData.firstName;
                    getProfileData.lastName = formData.lastName;
                    getProfileData.phoneNumber = formData.phoneNumber;
                    getProfileData.email = formData.email;
                    
                    const updateProfileResult = await userService.updateUserProfile(antiForgeryToken, getProfileData);
                    let updateProfileData = await updateProfileResult.json();
                    if (result.ok) {
                        enqueueSnackbar("Successfully updated profile");
                        progressTab();
                    } else {
                        enqueueSnackbar(updateProfileData.description);
                    }
                } else {
                    enqueueSnackbar(data.description);
                }
            }
        }
        else{
            progressTab();
        }
    };


    let content;
    if (isBusy) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        content = (
            <Box sx={{ maxWidth: 1200 }}>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <Typography sx={{mt:1,mb:2,fontSize:"1rem",fontWeight:850}}>Thank you for activating your Registered Agent account. View and update your contact information.</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={8} md={6} >
                            <Controller
                                name="title"
                                control={control}
                                defaultValue={accountData.title}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        select
                                        label="Title"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                    >
                                        <MenuItem key="0" value={0}>&nbsp;</MenuItem>
                                        <MenuItem key="1" value={1}>Mr</MenuItem>
                                        <MenuItem key="2" value={2}>Mrs</MenuItem>
                                        <MenuItem key="3" value={3}>Ms</MenuItem>
                                        <MenuItem key="4" value={4}>Miss</MenuItem>
                                        <MenuItem key="5" value={5}>Dr</MenuItem>
                                    </TextField>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="firstName"
                                control={control}
                                defaultValue={accountData.firstName}
                                rules={{ required: 'First Name is required' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="First Name *"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.firstName}
                                        helperText={formState.errors.firstName ? formState.errors.firstName.message : ''}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="lastName"
                                control={control}
                                defaultValue={accountData.lastName}
                                rules={{ required: 'Last Name is required' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Last Name *"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.lastName}
                                        helperText={formState.errors.lastName ? formState.errors.lastName.message : ''}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="phoneNumber"
                                control={control}
                                defaultValue={accountData.phoneNumber}
                                rules={{ required: 'Phone Number is required' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Phone *"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.phoneNumber}
                                        helperText={formState.errors.phoneNumber ? formState.errors.phoneNumber.message : ''}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="mobileNumber"
                                control={control}
                                defaultValue={accountData.mobileNumber}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Mobile"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.mobileNumber}
                                        helperText={formState.errors.mobileNumber ? formState.errors.mobileNumber.message : ''}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                        </Grid>
                        <Grid item xs={12} sm={8} md={6}>
                            <Controller
                                name="email"
                                control={control}
                                defaultValue={accountData.email}
                                rules={{
                                    required: 'Email is required',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: 'Invalid email address',
                                    },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Email *"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.email}
                                        helperText={formState.errors.email ? formState.errors.email.message : ''}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8} md={6} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                        </Grid>

                    </Grid>
                    <Stack direction="row" spacing={2} marginTop={2}>
                            <Button variant="contained" color="secondary" type="submit" disabled={disableSubmit}>
                                Save & Continue
                            </Button>
                    </Stack>
                </form>
            </Box>
        );
    }

    return (
        <>
            {content}
        </>
    );
}