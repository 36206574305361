import * as React from 'react';
import { useParams } from "react-router-dom";
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useContext, useEffect, useState} from "react";
import UpdateAccount from "./UpdateAccount";
import ListAccountCompanies from "./ListAccountCompanies";
import ListAccountUsers from "./ListAccountUsers";
import {useNavigate} from "react-router-dom";
import {ACCOUNT_TABS, BILLING_TABS, RESOURCE_TYPE, ROLES} from "../enums";
import accountService from "./AccountService";
import {enqueueSnackbar} from "notistack";
import AuthContext from "../api-authorization/AuthContext";
import {CircularProgress, Stack} from "@mui/material";
import ListCustomerProducts from "../products/ListCustomerProducts";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import NavigationContext from "../layout/NavigationContext";
import ListAccountPostBoxes from "./ListAccountPostBoxes";

export default function AccountParent() {
    const { histNavigate, replaceTopHist } = useContext(NavigationContext);
    const { selectedTab, accountId} = useParams();
    const [accountLoading, setAccountLoading] = useState(true);
    const [accountData, setAccountData] = useState();
    const { antiForgeryToken, user, refreshUser} = useContext(AuthContext);
    
    const [tabValue, setTabValue] = React.useState(selectedTab ? parseInt(selectedTab) : 0);
    const navigate = useNavigate();
    let content;
    const handleChange = (event, newTabValue) => {
        setTabValue(newTabValue);
    };

    useEffect( () => {
        getAccountData();
    }, [accountId])

    async function getAccountData() {
        const result = await accountService.getAccount(antiForgeryToken, accountId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                setAccountData(data);
                setAccountLoading(false);
            } else {
                enqueueSnackbar(data.description);
            }
        }
    }

    if (accountLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        content = (
            <>
                    {
                        user.role === ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD || user.role === ROLES.CLIENT_ADMIN || user.role === ROLES.CLIENT_STANDARD
                            ?
                            (
                                <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb: 2}}>
                                    Account - {accountData.email}
                                </Typography>
                            )
                            : (
                                <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb: 2}}>
                                    My Account
                                </Typography>
                            )
                    }
                <TabContext value={tabValue.toString()}>
                    <Box>
                        <Box>
                            
                            {
                                user.role === ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD
                                    || user.role === ROLES.CLIENT_ADMIN || user.role === ROLES.CLIENT_STANDARD
                                    ?
                                    (
                                        <TabList onChange={handleChange} aria-label="basic tabs example">
                                            <Tab label="Details" value={ACCOUNT_TABS.DETAILS.toString()} onClick={(e) =>{
                                                replaceTopHist(`/Account/Update/${accountId}/${ACCOUNT_TABS.DETAILS}`,'Update Account');
                                            }}/>
                                            {accountData.createUserLogins === true && (
                                                <Tab label="Users"  value={ACCOUNT_TABS.USERS.toString()} onClick={(e) =>{
                                                    replaceTopHist(`/Account/Update/${accountId}/${ACCOUNT_TABS.USERS}`,'Update Account');
                                                }}/>
                                            )}
                                            {accountData.clientBillingEnabled === true && (
                                                <Tab label="Billing" value={ACCOUNT_TABS.BILLING.toString()} onClick={(e) => {
                                                    e.preventDefault();
                                                    histNavigate(`/Billing/Update/${RESOURCE_TYPE.ACCOUNT}/${accountData.id}/${BILLING_TABS.PAYMENT_METHOD}`,'Billing');
                                                }}/>
                                            )}
                                            <Tab label="Companies" value={ACCOUNT_TABS.COMPANIES.toString()} onClick={(e) =>{
                                                replaceTopHist(`/Account/Update/${accountId}/${ACCOUNT_TABS.COMPANIES}`,'Update Account');
                                            }}/>
                                            <Tab label="Post Boxes" value={ACCOUNT_TABS.PO_BOXES.toString()} onClick={(e) =>{
                                                replaceTopHist(`/Account/Update/${accountId}/${ACCOUNT_TABS.PO_BOXES}`,'Update Account');
                                            }}/>
                                            <Tab label="Products" value={ACCOUNT_TABS.PRODUCTS.toString()} onClick={(e) =>{
                                                replaceTopHist(`/Account/Update/${accountId}/${ACCOUNT_TABS.PRODUCTS}`,'Update Account');
                                            }}/>
                                        </TabList>
                                    )
                                    : (
                                        <TabList onChange={handleChange} aria-label="basic tabs example">
                                            <Tab label="Details" value={ACCOUNT_TABS.DETAILS.toString()} onClick={(e) =>{
                                                replaceTopHist(`/Account/Update/${accountId}/${ACCOUNT_TABS.DETAILS}`,'Update Account');
                                            }}/>
                                            <Tab label="Billing" value={ACCOUNT_TABS.BILLING.toString()} onClick={(e) => {
                                                e.preventDefault();
                                                histNavigate(`/Billing/Update/${RESOURCE_TYPE.ACCOUNT}/${accountData.id}/${BILLING_TABS.PAYMENT_METHOD}`,'Billing');
                                            }}/>
                                            <Tab label="Products"  value={ACCOUNT_TABS.PRODUCTS.toString()} onClick={(e) =>{
                                                replaceTopHist(`/Account/Update/${accountId}/${ACCOUNT_TABS.PRODUCTS}`,'Update Account');
                                            }}/>
                                        </TabList>
                                    )
                            }
                        </Box>
                        {
                            user.role === ROLES.TENANT_ADMIN || user.role === ROLES.TENANT_STANDARD
                            || user.role === ROLES.CLIENT_ADMIN || user.role === ROLES.CLIENT_STANDARD
                        ?
                            (
                                <>
                                    <TabPanel value={ACCOUNT_TABS.DETAILS.toString()}>
                                        <UpdateAccount user={user} accountData={accountData} getAccountData={getAccountData} antiForgeryToken={antiForgeryToken} refreshUser={refreshUser}/>
                                    </TabPanel>
                                    {accountData.createUserLogins === true && (
                                        <TabPanel value={ACCOUNT_TABS.USERS.toString()}>
                                            <ListAccountUsers accountId={accountId} antiForgeryToken={antiForgeryToken} refreshUser={refreshUser} pageSize={20}/>
                                        </TabPanel>
                                    )}
                                    <TabPanel value={ACCOUNT_TABS.COMPANIES.toString()}>
                                        <ListAccountCompanies accountId={accountId} antiForgeryToken={antiForgeryToken} refreshUser={refreshUser}/>
                                    </TabPanel>
                                    <TabPanel value={ACCOUNT_TABS.PO_BOXES.toString()}>
                                        <ListAccountPostBoxes accountId={accountId} antiForgeryToken={antiForgeryToken} refreshUser={refreshUser}/>
                                    </TabPanel>
                                    <TabPanel value={ACCOUNT_TABS.PRODUCTS.toString()}>
                                        <ListCustomerProducts resourceType={RESOURCE_TYPE.ACCOUNT} resourceId={accountId} antiForgeryToken={antiForgeryToken} refreshUser={refreshUser}/>
                                    </TabPanel>
                                </>
                            )
                            : (
                                <>
                                    <TabPanel value={ACCOUNT_TABS.DETAILS.toString()}>
                                        <UpdateAccount user={user} accountData={accountData} getAccountData={getAccountData}  antiForgeryToken={antiForgeryToken} refreshUser={refreshUser}/>
                                    </TabPanel>
                                    <TabPanel value={ACCOUNT_TABS.PRODUCTS.toString()}>
                                        <ListCustomerProducts resourceType={RESOURCE_TYPE.ACCOUNT} resourceId={accountId} antiForgeryToken={antiForgeryToken} refreshUser={refreshUser} user={user}/>
                                    </TabPanel>
                                </>
                            )
                        }
                    </Box>
                </TabContext>
            </>
        );
    }

    return (
        <>
            {content}
        </>
    );
}