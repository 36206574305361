import React, {useContext, useState} from 'react'
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import {InputAdornment, MenuItem, Pagination, Stack} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import {enqueueSnackbar} from "notistack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AuthContext from "../api-authorization/AuthContext";
import mailService from "./MailService";
import dayjs from "dayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import Button from "@mui/material/Button";
import NavigationContext from "../layout/NavigationContext";
import {DataGridPro} from "@mui/x-data-grid-pro";

export default function ListClientMail() {
    const { histNavigate,back } = useContext(NavigationContext);
    const { antiForgeryToken,user, refreshUser} = useContext(AuthContext);
    const [isLoading, setIsLoading] = React.useState(true);
    const [mail, setMail] = React.useState([]);
    const [rowCountState, setRowCountState] = React.useState(0);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 20,
    });
    const navigate = useNavigate();

    const [searchTerm, setSearchTerm] = useState("");
    const [mailType, setMailType] = useState(null);
    const [mailProcessStatus, setMailProcessStatus] = useState(null);

    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);
    
    const fetchData = async () => {
        setIsLoading(true);
        setPaginationModel((prevModel) => ({...prevModel, page: 0}));
        let searchRequest = {
            clientId: user.clientId,
            appScope: 2,
            searchTerm: searchTerm,
            startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
            endDate : endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
            mailType : mailType,
            mailProcessStatus : mailProcessStatus,
            myMail: false
        };
        const result = await mailService.listMail(antiForgeryToken, searchRequest);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            setMail(data.mail);
            setRowCountState(data.mail.length);
            setIsLoading(false);
        } else {
            enqueueSnackbar(data.description);
        }
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    const handlePageChange = (params) => {
        setPaginationModel({
            page: params.page,
            pageSize: params.pageSize,
        });
    };

    const handleSearch = (event) => {
        event.preventDefault();
        fetchData();
    };

    const handleRowClick = (params) => {
        histNavigate(`/Mail/Update/${params.id}`,'Update Mail');
    };

    const dateFormatter = (params) => {
        const date = new Date(params.value);
        if (isNaN(date.getTime()) || date.getFullYear() <= 1) {
            return "None";
        }
        return date.toLocaleDateString('en-GB');
    };

    return (
        <Box sx={{height: 900}}>
            <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb:4}}>
                Mail search
            </Typography>
            <form onSubmit={handleSearch}>
                <TextField
                    id="search-bar"
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="end">
                                <IconButton type="submit">
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    label="Company search"
                    variant="outlined"
                    size="small"
                    style={{ width: '100%' }}
                />
                <Stack direction="row" spacing={2} marginTop={4} sx={{width: "80%"}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Start Date"
                            format={"DD/MM/YYYY"}
                            value={startDate}
                            onChange={(newValue) => setStartDate(newValue)}
                            slotProps={{ textField: { size: 'small' } }}
                        />
                        <DatePicker
                            label="End Date"
                            format={"DD/MM/YYYY"}
                            value={endDate}
                            onChange={(newValue) => setEndDate(newValue)}
                            slotProps={{ textField: { size: 'small' } }}
                        />
                    </LocalizationProvider>
                    <TextField
                        id="mailType"
                        select
                        label="Type"
                        value={mailType}
                        onChange={(e) => {
                            setMailType(e.target.value);
                            if(e.target.value == null) {
                                setMailType(null);
                            }
                        }}
                        variant="outlined"
                        margin="normal"
                        size="small"
                        sx={{mt:1, width:"35%"}}
                    >
                        <MenuItem key="0" value={null}>&nbsp;</MenuItem>
                        <MenuItem key="1" value={1}>Royal Mail Letter</MenuItem>
                        <MenuItem key="2" value={2}>Royal Mail Large Letter</MenuItem>
                        <MenuItem key="3" value={3}>Royal Mail Small Parcel</MenuItem>
                        <MenuItem key="4" value={4}>Royal Mail Medium Parcel</MenuItem>
                        <MenuItem key="5" value={5}>Royal Mail Large Parcel</MenuItem>
                        <MenuItem key="6" value={6}>Companies House</MenuItem>
                        <MenuItem key="7" value={7}>The Pension Regulator</MenuItem>
                        <MenuItem key="8" value={8}>HMRC</MenuItem>
                        <MenuItem key="9" value={9}>Other Special Delivery</MenuItem>
                        <MenuItem key="10" value={10}>Other Statutory Mail</MenuItem>
                    </TextField>
                    <TextField
                        id="mailProcessStatus"
                        select
                        label="Status"
                        value={mailProcessStatus}
                        onChange={(e) => {
                            setMailProcessStatus(e.target.value);
                            if(e.target.value == null) {
                                setMailProcessStatus(null);
                            }
                        }}
                        variant="outlined"
                        margin="normal"
                        size="small"
                        sx={{mt:1, width:"35%"}}
                    >
                        <MenuItem key="0" value={null}>&nbsp;</MenuItem>
                        <MenuItem key="1" value={1}>Received</MenuItem>
                        <MenuItem key="2" value={2}>Scanned</MenuItem>
                        <MenuItem key="3" value={3}>Sent</MenuItem>
                    </TextField>
                    <Button style={{height:"80%", width: "10%"}}  variant="contained" color="secondary" onClick={(e) => {
                        setStartDate(null);
                        setEndDate(null);
                        setMailType(null);
                        setMailProcessStatus(null);
                        setSearchTerm("");
                    }}>
                        Reset
                    </Button>
                    <Button style={{height:"80%", width: "10%"}}  variant="contained" color="secondary" type="submit">
                        Search
                    </Button>
                </Stack>

            </form>
            <br />
            <DataGridPro
                rows={mail.slice(
                    paginationModel.page * paginationModel.pageSize,
                    paginationModel.page * paginationModel.pageSize + paginationModel.pageSize
                )}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            postageFee: false,
                            handlingFee: false,
                        },
                    },
                }}
                columns={[
                    { field: 'receivedDate', headerName: 'Received Date', flex: 1, valueFormatter: dateFormatter},
                    { field: 'companyName', headerName: 'Company Name', flex: 1 },
                    { field: 'personName', headerName: 'Person Name', flex: 1 },
                    { field: 'mailType', headerName: 'Mail Type', flex: 1 },
                    { field: 'mailProcessStatus', headerName: 'Mail Status', flex: 1 },
                    { field: 'postageFee', headerName: 'Postage', flex: 1, valueFormatter: (params) => params.value?.toFixed(2) },
                    { field: 'handlingFee', headerName: 'Handling', flex: 1, valueFormatter: (params) => params.value?.toFixed(2) }
                ]}
                rowCount={paginationModel.pageSize}
                pageSize={paginationModel.pageSize}
                hideFooter={true}
                page={paginationModel.page}
                onPageChange={handlePageChange}
                loading={isLoading}
                onRowClick={handleRowClick}
                getRowClassName={() => 'rowCursorPointer'}
                autoHeight
                sx={{
                    '.MuiDataGrid-columnHeaderTitle': {
                        WebkitTextStroke: '0.75px'
                    },
                }}
            />
            <Box display="flex" justifyContent="flex-end">
                <Pagination
                    count={Math.ceil(rowCountState / paginationModel.pageSize)}
                    page={paginationModel.page + 1}
                    onChange={(event, page) => handlePageChange({ page: page - 1, pageSize: paginationModel.pageSize })}
                />
            </Box>
        </Box>
    );
}
