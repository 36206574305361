import * as React from 'react';
import { useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useContext, useEffect, useState} from "react";
import UpdateClient from "./UpdateClient";
import {useNavigate} from "react-router-dom";
import {CLIENT_TABS, ROLES} from "../enums";
import clientService from "./ClientService";
import ListClientUsers from "./ListClientUsers";
import {enqueueSnackbar} from "notistack";
import AuthContext from "../api-authorization/AuthContext";
import {CircularProgress} from "@mui/material";
import NavigationContext from "../layout/NavigationContext";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function ClientParent() {
    const { replaceTopHist } = useContext(NavigationContext);
    const { antiForgeryToken, refreshUser, user} = useContext(AuthContext);
    const { selectedTab, clientId} = useParams();
    const [clientLoading, setClientLoading] = useState(true);
    const [clientData, setClientData] = useState();

    const [tabValue, setTabValue] = React.useState(selectedTab ? parseInt(selectedTab) : 0);
    const navigate = useNavigate();
    let content;
    const handleChange = (event, newTabValue) => {
        setTabValue(newTabValue);
    };

    useEffect(() => {
        async function fetchData() {
            const result = await clientService.getClient(antiForgeryToken, clientId);
            if (result.status === 401) {
                refreshUser();
                navigate('/login');
            }
            else {
                let data = await result.json();
                if (result.ok) {
                    setClientData(data);
                    setClientLoading(false);
                } else {
                    enqueueSnackbar(data.description);
                }
            }
        }
        fetchData();
    }, [])
    
    
    if (clientLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        content = (
            <>
                <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb: 2}}>
                    Client - {clientData.companyName}
                </Typography>
                <Box>
                    <Box>
                        <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Details" id={`simple-tab-${CLIENT_TABS.DETAILS}`} aria-controls={`simple-tabpanel-${CLIENT_TABS.DETAILS}`} onClick={(e) =>{
                                replaceTopHist(`/Client/Update/${clientId}/${CLIENT_TABS.DETAILS}`,'Update Client');
                            }}/>
                            {clientData.userLogin === 'Enabled' ? <Tab label="Users" id={`simple-tab-${CLIENT_TABS.USERS}`} aria-controls={`simple-tabpanel-${CLIENT_TABS.USERS}`} onClick={(e) =>{
                                replaceTopHist(`/Client/Update/${clientId}/${CLIENT_TABS.USERS}`,'Update Client');
                            }}/> : null }
                        </Tabs>
                    </Box>
                    <TabPanel value={tabValue} index={0}>
                        <UpdateClient clientData={clientData} setClientData={setClientData} antiForgeryToken={antiForgeryToken} refreshUser={refreshUser} readonly={user.role != ROLES.TENANT_ADMIN}/>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <ListClientUsers clientId={clientId} antiForgeryToken={antiForgeryToken} refreshUser={refreshUser} user={user}/>
                    </TabPanel>
                </Box>
            </>
        );
    }

    return (
        <>
            {content}
        </>
    );
}