import * as React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useContext, useEffect, useState} from "react";
import {enqueueSnackbar} from "notistack";
import {CircularProgress, MenuItem, Stack} from "@mui/material";
import Button from "@mui/material/Button";
import {Controller, useForm} from "react-hook-form";
import {RESOURCE_TYPE} from "../enums";
import AuthContext from "../api-authorization/AuthContext";
import inputService from "../util/InputService";
import TextField from "@mui/material/TextField";
import {useTheme} from "@mui/material/styles";
import TransitionAlert from "../layout/TransitionAlert";
import billingService from "../billing/BillingService";
import {Elements} from "@stripe/react-stripe-js";
import SetupForm from "../billing/SetupForm";
import {loadStripe} from "@stripe/stripe-js";
import companyService from "./CompanyService";
import Divider from "@mui/material/Divider";
import NavigationContext from "../layout/NavigationContext";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function AddCompanyBillingParent() {
    const { companyId } = useParams();
    const { histNavigate, back } = useContext(NavigationContext);
    const { antiForgeryToken, refreshUser} = useContext(AuthContext);
    
    const theme = useTheme();
    const {  handleSubmit, control, formState, setValue } = useForm();
    const [personId, setPersonId] = React.useState("");
    const [tabValue, setTabValue] = React.useState(0);
    const navigate = useNavigate();
    
    
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [paymentFormLoading, setPaymentFormLoading] = useState(true);
    const [billingDetailsLoading, setBillingDetailsLoading] = useState(true);
    const [billingDetails, setBillingDetails] = useState();
    const [selectedBillingDetails, setSelectedBillingDetails] = useState({
        title: 0,
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        addressLine1:'',
        addressLine2:'',
        locality:'',
        country:'',
        postalCode:''
    });
    
    const [clientSecret, setClientSecret] = useState("");
    const [stripePublicKey, setStripePublicKey] = useState("");
    
    const handleChange = (event, newTabValue) => {
        setTabValue(newTabValue);
    };

    useEffect( () => {
        async function fetchBillingDetails() {
            const result = await companyService.getBillingDetails(antiForgeryToken, companyId);
            if (result.status === 401) {
                refreshUser();
                navigate('/login');
            }
            else {
                let data = await result.json();
                if (result.ok) {
                    setBillingDetails(data);
                    const primaryContact = data.persons.find(person => person.isPrimaryContact === true);
                    setPersonId(primaryContact.id);
                    setSelectedBillingDetails(prevState => ({
                        ...prevState,
                        title: primaryContact.title,
                        firstName: primaryContact.firstName,
                        lastName: primaryContact.lastName,
                        phoneNumber: primaryContact.phoneNumber,
                        email: primaryContact.email,
                        addressLine1: primaryContact.address.addressLine1,
                        addressLine2: primaryContact.address.addressLine2,
                        locality: primaryContact.address.locality,
                        country: primaryContact.address.country,
                        postalCode: primaryContact.address.postalCode
                    }));
                    setBillingDetailsLoading(false);
                } else {
                    enqueueSnackbar(data.description);
                }
            }
        }
        fetchBillingDetails();
    }, [companyId]);

    const handleAccountDetailsSubmit  = async (formData) => {
        setDisableSubmit(true);
        formData = inputService.trimFields(formData);
        formData.companyId = companyId;
        const result = await companyService.createOrUpdateStripeCompany(antiForgeryToken, formData);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                await getSetUpIntentSecret();
                setTabValue(1);
            } else {
                enqueueSnackbar(data.description);
            }
        }
        setDisableSubmit(false);
    };

    async function getSetUpIntentSecret() {
        const result = await billingService.getSetUpIntentSecret(antiForgeryToken, RESOURCE_TYPE.COMPANY, companyId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        else {
            let data = await result.json();
            if (result.ok) {
                setClientSecret(data.clientSecretId);
                setStripePublicKey(data.stripePublicKey);
                setPaymentFormLoading(false);
            } else {
                enqueueSnackbar(data.description);
            }
        }
    }



    let cardContent;
    if (paymentFormLoading) {
        cardContent = <p>Loading...</p>;
    } else {
        const options = {
            // passing the client secret obtained in step 3
            clientSecret: clientSecret,
            // Fully customizable with appearance API.
            appearance: {/*...*/},
        };
        const stripePromise = loadStripe(stripePublicKey);
        cardContent = (
            <Elements stripe={stripePromise} options={options}>
                <SetupForm resourceType={RESOURCE_TYPE.COMPANY} resourceId={companyId}/>
            </Elements>
        );
    }

    
    let content;
    if (billingDetailsLoading) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        let label= (
            <>
                <Typography>NOTE:    Please select a contact from the drop down to pre-populate the billing contact details.</Typography>
            </>
        );
        content = (
            <Box sx={{ width: '100%' }}>
                <Typography variant="h5" noWrap component="div" align={"left"} sx={{ mt: 2, mb:4 }}>
                    Billing - {billingDetails.companyName}
                </Typography>
                <Box>
                    <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Details" {...a11yProps(0)}/>
                        <Tab label="Payment Method" {...a11yProps(1)} disabled={paymentFormLoading}/>
                    </Tabs>
                </Box>
                <TabPanel value={tabValue} index={0}>
                    <TransitionAlert message={`NOTE:    Please select a contact from the drop down to pre-populate the billing contact details. The following information will appear on all future invoices for ${billingDetails.companyName}.`}/>
                    <Box sx={{ maxWidth: 600 }}>
                        <form onSubmit={handleSubmit(handleAccountDetailsSubmit)}>
                            <Controller
                                name="personId"
                                control={control}
                                defaultValue={personId}
                                rules={{required: 'Contact is required'}}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        select
                                        label="Company Contact *"
                                        variant="outlined"
                                        onChange={(e) => {
                                            field.onChange(e);
                                            const selectedPerson = billingDetails.persons.find(person => person.id === e.target.value);
                                            if (selectedPerson) {
                                                setValue('title', selectedPerson.title);
                                                setValue('firstName', selectedPerson.firstName);
                                                setValue('lastName', selectedPerson.lastName);
                                                setValue('phoneNumber', selectedPerson.phoneNumber);
                                                setValue('email', selectedPerson.email);
                                                setValue('address.addressLine1',selectedPerson.address.addressLine1);
                                                setValue('address.addressLine2',selectedPerson.address.addressLine2);
                                                setValue('address.locality',selectedPerson.address.locality);
                                                setValue('address.country',selectedPerson.address.country);
                                                setValue('address.postalCode',selectedPerson.address.postalCode);
                                            }
                                        }}
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.clientId}
                                        helperText={formState.errors.clientId ? formState.errors.clientId.message : ''}
                                    >
                                        {billingDetails.persons.map((person) => (
                                            <MenuItem key={person.id} value={person.id}>
                                                {person.contactOneLine}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                            />
                            <Divider sx={{ borderBottomWidth: 1, borderColor: "black", mt:2,mb:2}}  />
                            
                            <Controller
                                name="title"
                                control={control}
                                defaultValue={selectedBillingDetails.title}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        select
                                        disabled={true}
                                        label="Title"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                    >
                                        <MenuItem key="0" value={0}>&nbsp;</MenuItem>
                                        <MenuItem key="1" value={1}>Mr</MenuItem>
                                        <MenuItem key="2" value={2}>Mrs</MenuItem>
                                        <MenuItem key="3" value={3}>Ms</MenuItem>
                                        <MenuItem key="4" value={4}>Miss</MenuItem>
                                        <MenuItem key="5" value={5}>Dr</MenuItem>
                                    </TextField>
                                )}
                            />
                            <Controller
                                name="firstName"
                                control={control}
                                defaultValue={selectedBillingDetails.firstName}
                                rules={{ required: 'First Name is required' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        disabled={true}
                                        label="First Name *"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.firstName}
                                        helperText={formState.errors.firstName ? formState.errors.firstName.message : ''}
                                    />
                                )}
                            />
                            <Controller
                                name="lastName"
                                control={control}
                                defaultValue={selectedBillingDetails.lastName}
                                rules={{ required: 'Last Name is required' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        disabled={true}
                                        label="Last Name *"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.lastName}
                                        helperText={formState.errors.lastName ? formState.errors.lastName.message : ''}
                                    />
                                )}
                            />
                            <Controller
                                name="phoneNumber"
                                control={control}
                                defaultValue={selectedBillingDetails.phoneNumber}
                                rules={{ required: 'Phone Number is required' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        disabled={true}
                                        label="Phone *"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.phoneNumber}
                                        helperText={formState.errors.phoneNumber ? formState.errors.phoneNumber.message : ''}
                                    />
                                )}
                            />
                            <Controller
                                name="email"
                                control={control}
                                defaultValue={selectedBillingDetails.email}
                                rules={{ required: 'Email is required' }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        disabled={true}
                                        label="Email *"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                        error={!!formState.errors.email}
                                        helperText={formState.errors.email ? formState.errors.email.message : ''}
                                    />
                                )}
                            />
                            <Typography variant="h6" noWrap component="div" align={"left"} sx={{ mt: 2 }}>
                                Address details
                            </Typography>
                            <Controller
                                name="address.addressLine1"
                                control={control}
                                defaultValue={selectedBillingDetails.addressLine1}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Address Line 1"
                                        disabled={true}
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                    />
                                )}
                            />
                            <Controller
                                name="address.addressLine2"
                                control={control}
                                defaultValue={selectedBillingDetails.addressLine2}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Address Line 2"
                                        disabled={true}
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                    />
                                )}
                            />
                            <Controller
                                name="address.locality"
                                control={control}
                                defaultValue={selectedBillingDetails.locality}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Town / City"
                                        disabled={true}
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                    />
                                )}
                            />
                            <Controller
                                name="address.country"
                                control={control}
                                defaultValue={selectedBillingDetails.country}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Country"
                                        disabled={true}
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                    />
                                )}
                            />
                            <Controller
                                name="address.postalCode"
                                control={control}
                                defaultValue={selectedBillingDetails.postalCode}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        label="Post Code"
                                        disabled={true}
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: { ...theme.inputLabelProps },
                                        }}
                                        margin="normal"
                                        fullWidth
                                        size="small"
                                    />
                                )}
                            />
                            <Stack direction="row" spacing={2} marginTop={2}>
                                <Button variant="contained" color="secondary" onClick={(e) => {
                                    back();
                                }}>
                                    Back
                                </Button>
                                <Button variant="contained" color="secondary" type="submit" disabled={disableSubmit}>
                                    Next
                                </Button>
                            </Stack>
                        </form>
                    </Box>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                        {cardContent}
                </TabPanel>
            </Box>
        );
    }

    return (
        <>
            {content}
        </>
    );
}