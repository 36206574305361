import React, {useContext, useEffect, useState} from 'react'
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import {CircularProgress, InputAdornment, MenuItem, Pagination, Stack} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import {enqueueSnackbar} from "notistack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AuthContext from "../api-authorization/AuthContext";
import mailService from "./MailService";
import clientService from "../clients/ClientService";
import Button from "@mui/material/Button";
import NavigationContext from "../layout/NavigationContext";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {generateMailLabelXml} from "../labels/GenerateMailLabelXml";
import labelService from "../labels/LabelService";

export default function CheckoutMail() {
    const { histNavigate,back } = useContext(NavigationContext);
    const { antiForgeryToken, refreshUser} = useContext(AuthContext);
    const [clientData, setClientData] = useState();
    const [filterDescription, setFilterDescription] = useState('');
    const [isClientDataLoading, setIsClientDataLoading] = useState(true);
    const [isCheckingOut, setIsCheckingOut] = useState(false);
    
    const [searchTerm, setSearchTerm] = useState('');
    const [forwardingFrequency, setForwardingFrequency] = useState('');
    const [forwardingDay, setForwardingDay] = useState('');
    const [mailHold, setMailHold] = useState(null);
    const [mailCollection, setMailCollection] = useState('');
    const [slotSelection, setSlotSelection] = useState('');
    const [clientId, setClientId] = useState('');
    
    const [isLoading, setIsLoading] = React.useState(true);
    const [mail, setMail] = React.useState([]);
    const [rowCountState, setRowCountState] = React.useState(0);
    const [selectedRows, setSelectedRows] = useState([]);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
    });
    const navigate = useNavigate();
    
    useEffect(() => {
        async function fetchClientData() {
            const result = await clientService.listClients(antiForgeryToken, "");
            if (result.status === 401) {
                refreshUser();
                navigate('/login');
            } else {
                let data = await result.json();
                if (result.ok) {
                    setClientData(data.clients);
                } else {
                    enqueueSnackbar(data.description);
                }
            }
            setIsClientDataLoading(false);
        }
        fetchClientData();
    }, [])

    const fetchData = async () => {
        setPaginationModel((prevModel) => ({...prevModel, page: 0}));
        setIsLoading(true);
        let searchRequest = {
            searchTerm: searchTerm == '' ? null : searchTerm,
            mailForwardingFrequency: forwardingFrequency == '' ? null : forwardingFrequency,
            mailForwardingDay : forwardingDay == '' ? null : forwardingDay,
            mailHold : mailHold,
            mailCollection : mailCollection == '' ? null : mailCollection,
            clientId: clientId == '' ? null : clientId,
            mailSlot: slotSelection == '' ? null : slotSelection
        };
        const result = await mailService.listCheckoutMail(antiForgeryToken, searchRequest);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            setFilterDescription(data.filterDescription);
            setMail(data.mail);
            setRowCountState(data.mail.length);
            setIsLoading(false);
        } else {
            enqueueSnackbar(data.description);
            setIsLoading(false);
        }
    };

    const openCoverSheet = async (mailCheckoutId) => {
        const result = await mailService.getCheckoutCoverSheet(antiForgeryToken, mailCheckoutId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            var newWindow = window.open("", "_blank");
            newWindow.document.write(data);
        } else {
            enqueueSnackbar(data.description);
        }
    };

    const printLabels = async (mailCheckoutId) => {
        const result = await labelService.getCheckoutLabels(antiForgeryToken, mailCheckoutId);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            let printers = window.dymo.label.framework.getPrinters();
            let connectedPrinter = printers.find(printer => printer.isConnected === true);
            if(!connectedPrinter){
                enqueueSnackbar("An error occured: No printers found");
                Object.keys(data.labels).forEach(key => {
                    const xml = generateMailLabelXml(data.labels[key]);
                    console.log(xml);
                });
            }
            else {
                Object.keys(data.labels).forEach(key => {
                    const xml = generateMailLabelXml(data.labels[key]);
                    let label = window.dymo.label.framework.openLabelXml(xml);
                    label.print(connectedPrinter.name);
                });
            }

        } else {
            enqueueSnackbar(data.description);
        }
    };

    const checkoutMail = async () => {
        setIsCheckingOut(true);
        let formData = {
            mailIds: selectedRows,
            description: filterDescription
        };
        const result = await mailService.checkoutMail(antiForgeryToken, formData);
        if (result.status === 401) {
            refreshUser();
            navigate('/login');
        }
        let data = await result.json();
        if (result.ok) {
            enqueueSnackbar("Successfully checked mail items: ");
            printLabels(data);
            openCoverSheet(data);
            fetchData();
        } else {
            enqueueSnackbar(data.description);
        }
        setIsCheckingOut(false);
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    const handlePageChange = (params) => {
        setPaginationModel({
            page: params.page,
            pageSize: params.pageSize,
        });
    };

    const handleSearch = (event) => {
        event.preventDefault();
        fetchData();
    };
    
    const dateFormatter = (params) => {
        const date = new Date(params.value);
        if (isNaN(date.getTime()) || date.getFullYear() <= 1) {
            return "None";
        }
        return date.toLocaleString('en-GB');
    };
    let content;
    if (isClientDataLoading || isCheckingOut) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
                <CircularProgress />
            </Box>
        );
    } else {
        content = (
            <Box sx={{height: 900}}>
                <Typography variant="h5" noWrap component="div" align={"left"} sx={{mb:4}}>
                    Check-out Mail
                </Typography>
                <form onSubmit={handleSearch}>
                    <TextField
                        id="search-bar"
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="end">
                                    <IconButton type="submit">
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        label="Company search"
                        variant="outlined"
                        size="small"
                        style={{ width: '100%' }}
                    />
                    <Stack direction="row" spacing={2} marginTop={2}>
                        <TextField
                            id="forwardingFreq"
                            select
                            label="Forwarding Freq."
                            value={forwardingFrequency}
                            onChange={(e) => {
                                setForwardingFrequency(e.target.value);
                                if(e.target.value == '') {
                                    setForwardingDay('');
                                }
                            }}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            size="small"
                            style={{marginTop:"1rem"}}
                        >
                            <MenuItem key="0" value={''}>&nbsp;</MenuItem>
                            <MenuItem key="1" value={1}>Daily</MenuItem>
                            <MenuItem key="2" value={2}>Weekly</MenuItem>
                            <MenuItem key="3" value={3}>Monthly</MenuItem>
                        </TextField>
                        <TextField
                            id="forwardingDay"
                            select
                            label="Forwarding Day"
                            value={forwardingDay}
                            onChange={(e) => {
                                setForwardingDay(e.target.value);
                            }}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            size="small"
                            style={{marginTop:"1rem"}}
                            disabled={forwardingFrequency <= 1 || !forwardingFrequency}
                        >
                            {forwardingFrequency === 2 ?
                                [
                                    {value: "1", name: "Monday"},
                                    {value: "2", name: "Tuesday"},
                                    {value: "3", name: "Wednesday"},
                                    {value: "4", name: "Thursday"},
                                    {value: "5", name: "Friday"},
                                    {value: "6", name: "Saturday"},
                                    {value: "0", name: "Sunday"}
                                ].map(day => (
                                    <MenuItem key={day.value} value={day.value}>{day.name}</MenuItem>
                                ))
                                : forwardingFrequency === 3 ? // If "Monthly"
                                    Array.from({length: 31}, (_, i) => i + 1).map((day) => (
                                        <MenuItem key={day} value={day}>{day}</MenuItem>
                                    ))
                                    : <MenuItem key="0" value={''}>&nbsp;</MenuItem>
                            }
                        </TextField>
                        <TextField
                            id="mailHold"
                            select
                            label="Mail Hold"
                            value={mailHold}
                            onChange={(e) => {
                                setMailHold(e.target.value);
                            }}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            size="small"
                            style={{marginTop:"1rem"}}
                        >
                            <MenuItem key="" value={null}>&nbsp;</MenuItem>
                            <MenuItem key="1" value={0}>None</MenuItem>
                            <MenuItem key="2" value={1}>Yes - Non-Official</MenuItem>
                            <MenuItem key="3" value={2}>Yes - All</MenuItem>
                            <MenuItem key="3" value={3}>Collection</MenuItem>
                            <MenuItem key="4" value={4}>Shred</MenuItem>
                            <MenuItem key="5" value={5}>Re-Enclose</MenuItem>
                        </TextField>
                        <TextField
                            id="mailCollection"
                            select
                            label="Mail Collection"
                            value={mailCollection}
                            onChange={(e) => {
                                setMailCollection(e.target.value);
                            }}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            size="small"
                            style={{marginTop:"1rem"}}
                        >
                            <MenuItem key="0" value={''}>&nbsp;</MenuItem>
                            <MenuItem key="1" value={false}>No</MenuItem>
                            <MenuItem key="2" value={true}>Yes</MenuItem>
                        </TextField>
                        <TextField
                            id="slotSelection"
                            select
                            label="Slot"
                            value={slotSelection}
                            onChange={(e) => {
                                setSlotSelection(e.target.value);
                            }}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            size="small"
                            style={{marginTop:"1rem"}}
                        >
                            <MenuItem key="0" value={''}>&nbsp;</MenuItem>
                            <MenuItem key="1" value={1}>ABC</MenuItem>
                            <MenuItem key="2" value={2}>DEF</MenuItem>
                            <MenuItem key="3" value={3}>GHI</MenuItem>
                            <MenuItem key="4" value={4}>JKL</MenuItem>
                            <MenuItem key="5" value={5}>MNO</MenuItem>
                            <MenuItem key="6" value={6}>PQR</MenuItem>
                            <MenuItem key="7" value={7}>STU</MenuItem>
                            <MenuItem key="8" value={8}>VWX</MenuItem>
                            <MenuItem key="9" value={9}>YZ0+</MenuItem>
                        </TextField>
                        <TextField
                            id="clientId"
                            select
                            label="Client"
                            value={clientId}
                            onChange={(e) => {
                                setClientId(e.target.value);
                            }}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            size="small"
                            style={{marginTop:"1rem"}}
                        >
                            <MenuItem key="0" value={''}>&nbsp;</MenuItem>
                            {clientData.map((client) => (
                                <MenuItem key={client.id} value={client.id}>
                                    {client.companyName}
                                </MenuItem>
                            ))}
                        </TextField>
                        <Button style={{height:"80%", marginTop:"1rem", width: "20%"}}  variant="contained" color="secondary" type="submit">
                            Search
                        </Button>
                    </Stack>

                </form>
                <br />
                <DataGridPro
                    rows={mail}
                    columns={[
                        { field: 'receivedDate', headerName: 'Received Date', flex: 1, valueFormatter: dateFormatter},
                        { field: 'companyName', headerName: 'Company Name', flex: 1 },
                        { field: 'personName', headerName: 'Person Name', flex: 1 },
                        { field: 'mailType', headerName: 'Mail Type', flex: 1 },
                        { field: 'mailSlot', headerName: 'Slot', flex: 1 },
                        { field: 'clientName', headerName: 'Client', flex: 1 }
                    ]}
                    pageSize={mail.length}
                    hideFooter={true}
                    page={paginationModel.page}
                    onPageChange={handlePageChange}
                    loading={isLoading}
                    checkboxSelection
                    disableRowSelectionOnClick
                    onRowSelectionModelChange = {(newSelection) => {
                        setSelectedRows(newSelection);
                    }}
                    selectionModel={selectedRows}
                    autoHeight
                    sx={{
                        '.MuiDataGrid-columnHeaderTitle': {
                            WebkitTextStroke: '0.75px'
                        },
                    }}
                />
                <Stack direction="row" spacing={2} marginTop={2}>
                    <Button variant="contained" color="secondary" onClick={(e) => {checkoutMail()}} disabled={selectedRows.length <= 0}>
                        Check-out
                    </Button>
                    <Button variant="contained" color="secondary" onClick={(e) => {
                        e.preventDefault();
                        histNavigate(`/Mail/ListMailCheckouts`,'Mail Checkouts');
                    }}>
                        Check-out History
                    </Button>
                </Stack>
            </Box>
        );
    }

    return (
        <>
            {content}
        </>
    );
}
